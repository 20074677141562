import Swal from 'sweetalert2/dist/sweetalert2.js';
import { icp_request } from '../../config/axios';
import router from '../../router';
//import {wait} from '../../plugins/vue-wait';
import "sweetalert2/src/sweetalert2.scss";

export default {
  namespaced: true,
  state: {
    all_cnfig_home: [],
    all_legal_form: [],
    token: "",
    user: JSON.parse(localStorage.getItem("userData")),
    active: false,
    mail_done: false,
    loadLogin: false,
    loadPpm: false,
    loadPpmRev: false,
    loadNotice: false,
    loadDoc: false,
    loadSignup: false,
    avis_modal: false,
    doc_modal: false,
    postponementList:[],
    GetFile: false,
    selectedPpm: [],
    selectedObs: [],
    selectedEvaluation:[],
    public_rep_offer:[],
    OfferKeys:[],
    selectedPpmRev: [],
    listHomeDocs: [],
    listComUser: [],
    syncSO:[],
    syncEVAL:[],
    syncCONTRACT:[],
    listEvaluation:[],
    selectedMeetingPv:[],
    selectedSessionListPresence:[],
    listContract:[],
    listContractByUser:[],
    listVisitPresence:[],
    listSubmissionFoldNoti:[],
    listPv: [],
    listRemovedOffer:[],
    selectedDoc: [],
    selectedCom: [],
    selectedRecourse:[],
    attendanceList:[],
    selectedAttribution:[],
    selectedContract:[],
    user_access_doc:[],
    selectedOffer: [],
    selectedRequestResponse: [],
    listMeetingPresence:[],
    listMessage: [],
    listVisit: [],
    loadPublicNoticeType: [],
    AllRecourse:[],
    AllSignature:[],
    SignatureEval:[],
    listSession:[],
    listOffer: [],
    listModel:[],
    listRequest: [],
    public_notice: [],
    selectedSession:[],
    selectedModelpv:[],
    selectedModelpvBySession:[],
    selectedVisit: [],
    selectedVisitPv:[],
    listTdr: [],
    syncATTR:[],
    listMeeting: [],
    listCom: [],
    listChartMemebers: [],
    listDocAdd: [],
    selectedNotice: [],
    ppm_done: false,
    goLogin: "signup",
    listPpm: [],
    listAttribution:[],
    listAvis: [],
    selectedMeeting: [],
    selectedRequest: [],
    listAccessRequest:[],
    listDoc: [],
    listAllRevPpm: [],
    remainingTime:0,
  },
  mutations: {
    SET_REMAINING_TIME: (state, value) => (state.remainingTime = value),
    SET_ALL_LEGAL_FORM: (state, value) => (state.all_legal_form = value),
    SET_TOKEN(state, value) {
      state.token = value;
      if (state.token) {
        state.active = true;
      } else {
        state.active = false;
      }
    },
    SET_ALL_CONFIG_HOME: (state, value) => {
      state.all_cnfig_home = value;
    },
    SET_ALL_REMOVED_OFFER: (state, value) => {
      state.listRemovedOffer = value;
    },
    SET_LIST_REQUEST: (state, value) => {
      state.listRequest = value;
    },
    SET_ACTIVE: (state, value) => (state.active = value),
    SET_USER: (state, value) => localStorage.setItem('userData', JSON.stringify(value)),
    SET_MAIL_DONE: (state, value) => (state.mail_done = value),
    LOG_OUT(state) {
      state.token = null;
      delete icp_request.defaults.headers.common['Authorization'];
    },
    LOAD_LOGIN(state, value) {
      state.loadLogin = value;
    },
    LOAD_PPM(state, value) {
      state.loadPpm = value;
    },
    SET_MESSAGE_LIST(state, value) {
      state.listMessage = value;
    },
    SET_HOME_DOCS(state, value) {
      state.listHomeDocs = value;
    },
    LOAD_NOTICE(state, value) {
      state.loadNotice = value;
    },
    SET_SYNCSO(state, value) {
      state.syncSO = value;
    },
    SET_SYNCATTR(state, value) {
      state.syncATTR = value;
    },
    SET_SYNCEVAL(state, value) {
      state.syncEVAL = value;
    },
    SET_SYNCCONTRACT(state, value) {
      state.syncCONTRACT = value;
    },
    LOAD_DOC(state, value) {
      state.loadDoc = value;
    },
    LOAD_PPMREVADD(state, value) {
      state.loadPpmRev = value;
    },
    LOAD_SIGNUP(state, value) {
      state.loadSignup = value;
    },
    GET_FILE(state, value) {
      state.GetFile = value;
    },
    SET_LIST_TDR(state, value) {
      state.listTdr = value;
    },
    SET_SELECTED_EVALUATION(state, value) {
      state.selectedEvaluation = value;
    },
    SET_SELECTED_ATTRIBUTION(state, value) {
      state.selectedAttribution = value;
    },
    SET_SELECTED_CONTRACT(state, value) {
      state.selectedContract = value;
    },
    SET_LIST_OFFER(state, value) {
      state.listOffer = value;
    },
    SET_LIST_MODEL(state, value) {
      state.listModel = value;
    },
    SET_LIST_SESSION(state, value) {
      state.listSession = value;
    },
    SET_OFFER_KEYS(state, value) {
      state.OfferKeys = value;
    },
    SET_COM_LIST(state, value) {
      state.listCom = value;
    },
    SET_LIST_ADD(state, value) {
      state.listDocAdd = value;
    },
    SET_ALL_ACCESS_REQUEST(state, value) {
      state.listAccessRequest = value;
    },
    SET_PV_LIST(state, value) {
      state.listPv = value;
    },
    SET_SELECTED_RECOURSE(state, value){
      state.selectedRecourse = value;
    },
    SET_SELECTED_PPM(state, value) {
      state.selectedPpm = value;
    },
    SET_SELECTED_PPM_REV(state, value) {
      state.selectedPpmRev = value;
    },
    SET_ATTENDANCE_LIST(state, value) {
      state.attendanceList = value;
    },
    SET_SELECTED_SESSION(state, value) {
      state.selectedSession = value;
    },
    SET_SELECTED_MODELPV(state, value) {
      state.selectedModelpv = value;
    },
    SET_SELECTED_MODELPVBYSESSION(state, value) {
      state.selectedModelpvBySession = value;
    },
    SET_SELECTED_SESSION_LIST_PRESENCE(state, value) {
      state.selectedSessionListPresence = value;
    },
    SET_SELECTED_MEETING(state, value) {
      state.selectedMeeting = value;
    },
    SET_SELECTED_MEETING_PV(state, value) {
      state.selectedMeetingPv = value;
    },
    SET_SELECTED_REQUEST(state, value) {
      state.selectedRequest = value;
    },
    SET_SELECTED_COM(state, value) {
      state.selectedCom = value;
    },
    SET_SELECTED_OFFER(state, value) {
      state.selectedOffer = value;
    },
    SET_POSTPONEMENT_LIST(state, value) {
      state.postponementList = value;
    },
    SET_PUBLIC_NOTICE(state, value) {
      state.public_notice = value;
    },
    SET_PUBLIC_REP_OFFER(state, value) {
      state.public_rep_offer = value;
    },
    SET_SELECTED_REQUEST_RESPONSE(state, value) {
      state.selectedRequestResponse = value;
    },
    SET_SELECTED_VISIT(state, value) {
      state.selectedVisit = value;
    },
    SET_SELECTED_VISIT_PV(state, value) {
      state.selectedVisitPv = value;
    },
    SET_CHART_MEMBERS(state, value) {
      state.listChartMemebers = value;
    },
    CLOSE_MAIL(state, value) {
      state.mail_done = value;
    },
    CLOSE_PPM(state, value) {
      state.ppm_done = value;
    },
    OPEN_AVIS(state, value) {
      state.avis_modal = value;
    },
    OPEN_DOC(state, value) {
      state.doc_modal = value;
    },
    SET_GO_LOGIN(state, value) {
      state.goLogin = value;
    },
    SET_LIST_PPM(state, value) {
      state.listPpm = value;
    },
    SET_LIST_ATTRIBUTION(state, value) {
      state.listAttribution = value;
    },
    SET_LIST_CONTRACT(state, value) {
      state.listContract = value;
    },
    SET_SELECTED_USER_CONTRACT(state, value) {
      state.listContractByUser = value;
    },
    SET_LIST_AVIS(state, value) {
      state.listAvis = value;
    },
    SET_COM_USER_LIST(state, value) {
      state.listComUser = value;
    },
    SET_USER_ACCESS_DOC(state, value) {
      state.user_access_doc = value;
    },
    SET_LIST_VISIT_PRESENCE(state,value){
      state.listVisitPresence = value;
    },
    SET_LIST_MEETING_PRESENCE(state,value){
      state.listMeetingPresence = value;
    },
    SET_LIST_DOC(state, value) {
      state.listDoc = value;
    },
    SET_SubmissionFoldNoti_LIST(state, value) {
      state.listSubmissionFoldNoti = value;
    },
    SET_SELECTED_NOTICE(state, value) {
      state.selectedNotice = value;
    },
    SET_ALL_RECOURSE(state, value) {
      state.AllRecourse = value;
    },
    SET_ALL_SIGNATURE(state, value) {
      state.AllSignature = value;
    },
    SET_EVAL_SIGNATURE(state, value) {
      state.SignatureEval = value;
    },
    SET_PUBLIC_NOTICE_TYPE(state, value) {
      state.loadPublicNoticeType = value;
    },
    SET_LIST_MEETING(state, value) {
      state.listMeeting = value;
    },
    SET_LIST_EVALUATION(state, value) {
      state.listEvaluation = value;
    },
    SET_LIST_VISIT(state, value) {
      state.listVisit = value;
    },
    SET_SELECTED_DOC(state, value) {
      state.selectedDoc = value;
    },
    SET_REV_PPM(state, value) {
      state.listAllRevPpm = value;
    }
  },
  getters: {
    getRemainingTime(state){
      return state.remainingTime;
    },
    isActive(state) {
      return state.active;
    },
    isPpmDone(state) {
      return state.ppm_done;
    },
    getAllRecourse(state){
      return state.AllRecourse;
    },
    get_all_signature(state){
      return state.AllSignature;
    },
    get_eval_signature(state){
      return state.SignatureEval;
    },
    getlistSubmissionFoldNoti(state){
      return state.listSubmissionFoldNoti;
    },
    isMailDone(state) {
      return state.mail_done;
    },
    loadLogin(state) {
      return state.loadLogin;
    },
    loadSignup(state) {
      return state.loadSignup;
    },
    loadPpm(state) {
      return state.loadPpm;
    },
    loadNotice(state) {
      return state.loadNotice;
    },
    loadDoc(state) {
      return state.loadDoc;
    },
    loadPpmRev(state) {
      return state.loadPpmRev;
    },
    get_all_legal_form(state) {
      return state.all_legal_form;
    },
    get_all_attribution(state) {
      return state.listAttribution;
    },
    get_all_contract(state) {
      return state.listContract;
    },
    get_all_contract_by_user(state) {
      return state.listContractByUser;
    },
    get_list_removed_offer(state){
      return state.listRemovedOffer
    },
    get_all_evaluation(state){
      return state.listEvaluation;
    },
    get_all_pv(state) {
      return state.listPv;
    },
    getMeetingList(state) {
      return state.listMeeting;
    },
    get_offer_keys(state){
      return state.OfferKeys;
    },
    getVisitList(state) {
      return state.listVisit;
    },
    get_user_access_doc(state) {
      return state.user_access_doc;
    },
    get_list_access_request(state){
      return state.listAccessRequest
    },
    get_postponement_list(state){
      return state.postponementList
    },
    getlistChartMemebers(state) {
      return state.listChartMemebers;
    },
    get_sync_so(state) {
      return state.syncSO;
    },
    get_sync_attr(state) {
      return state.syncATTR;
    },
    get_sync_eval(state) {
      return state.syncEVAL;
    },
    get_sync_contract(state) {
      return state.syncCONTRACT;
    },
    getListVisitPresence(state) {
      return state.listVisitPresence;
    },
    getListMeetingPresence(state) {
      return state.listMeetingPresence;
    },

    getTdr(state) {
      return state.listTdr;
    },
    getCom(state) {
      return state.listCom;
    },
    getHomeDocs(state) {
      return state.listHomeDocs;
    },
    getComUserList(state) {
      return state.listComUser;
    },
    getDocAdd(state) {
      return state.listDocAdd;
    },
    isGetFile(state) {
      return state.GetFile;
    },
    get_selected_ppm(state) {
      return state.selectedPpm;
    },
    get_selected_session_list_presence(state) {
      return state.selectedSessionListPresence;
    },
    get_selected_session(state) {
      return state.selectedSession;
    },
    get_selected_model_pv(state) {
      return state.selectedModelpv;
    },
    get_selected_model_pv_by_session(state){
      return state.selectedModelpvBySession;
    },
    get_selected_ppm_rev(state) {
      return state.selectedPpmRev;
    },
    get_list_session(state){
      return state.listSession;
    },
    get_selected_offer(state) {
      return state.selectedOffer;
    },
    get_selected_meeting(state) {
      return state.selectedMeeting;
    },
    get_selected_meeting_pv(state) {
      return state.selectedMeetingPv;
    },
    get_selected_visit(state) {
      return state.selectedVisit;
    },
    get_selected_visit_pv(state) {
      return state.selectedVisitPv;
    },
    get_selected_recourse(state){
      return state.selectedRecourse;
    },
    get_selected_evaluation(state){
      return state.selectedEvaluation;
    },
    get_selected_attribution(state){
      return state.selectedAttribution;
    },
    get_selected_contract(state){
      return state.selectedContract;
    },
    get_selected_com(state) {
      return state.selectedCom;
    },
    get_selected_doc(state) {
      return state.selectedDoc;
    },
    get_selected_request(state) {
      return state.selectedRequest;
    },
    get_selected_request_response(state) {
      return state.selectedRequestResponse;
    },
    get_selected_notice(state) {
      return state.selectedNotice;
    },
    get_user(state) {
      return state.user;
    },
    is_avis_open(state) {
      return state.avis_modal;
    },
    is_doc_open(state) {
      return state.doc_modal;
    },
    getListPpm(state) {
      return state.listPpm;
    },
    getOfferList(state) {
      return state.listOffer;
    },
    getModelList(state) {
      return state.listModel;
    },
    get_all_cnfig_home(state) {
      return state.all_cnfig_home;
    },
    get_attendance_list(state){
      return state.attendanceList;
    },
    getListAvis(state) {
      return state.listAvis;
    },
    getListRequest(state) {
      return state.listRequest;
    },
    getListDoc(state) {
      return state.listDoc;
    },
    getListMessage(state) {
      return state.listMessage;
    },
    getRevPpm(state) {
      return state.listAllRevPpm;
    },
    GET_PUBLIC_REP_OFFER(state) {
      return state.public_rep_offer;
    },
    get_goLogin(state) {
      return state.goLogin;
    },
    get_public_notice(state) {
      return state.public_notice;
    },
    get_public_notice_type(state) {
      return state.loadPublicNoticeType;
    }
  },
  actions: {
    async attempt({ commit }, token) {
      if (token) {
        localStorage.setItem("icp_pass", token);
        icp_request.defaults.headers.common.Authorization = "Bearer " + token;
        return await commit("SET_TOKEN", token);
      }else{
        delete icp_request.defaults.headers.common['Authorization'];
      }
    },
    async Notify_Pop({ commit,dispatch }, response) {
      if (response) {
        if (response.data.status == 0) {
          Swal.fire({
            title: "Succès !",
            text: response.data.message,
            icon: "success",  
            confirmButtonText: "OK"
          });
          if (response.data.message == "Utilisateur ajouté avec succès !") {
            commit("SET_GO_LOGIN", "login");
          }
          commit("SET_MAIL_DONE", false);
        } else {
          if(!response.data.message == "Le token est invalide."){
            return Swal.fire({
              title: "Attention!",
              text: response.data.message,
              icon: "error",
              confirmButtonText: "OK"
            });
          }else{
            return Swal.fire({
              title: "Attention!",
              text: response.data.message,
              icon: "error",
              confirmButtonText: "OK"
            })
            // .then((result) => {
            //   if (result['isConfirmed']){
            //     dispatch("logOut");
            //   }
            // })
          }
        }
      }
    },
    async resetEmail({ dispatch }, email) {
      await icp_request
        .post("password/email/send", email, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
          dispatch("Notify_Pop", response);
        })
    },
    getData({ commit }) {
      icp_request
        .get("public/types/all")
        .then(response => {
          commit("SET_ALL_LEGAL_FORM", response.data.data);
        })
    },
    async resetPassword_byEmail({ dispatch }, newAcesss) {
      await icp_request
        .post("password/reset", newAcesss, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
          if (response.status) {
            router.push({ name: "Connexion" });
          }
          dispatch("Notify_Pop", response);
        })
    },
    async representatorCheck({ dispatch, commit }, newAcesss) {
      await icp_request
        .post("representator/validity_check", newAcesss, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
          if (response.status) {
            commit("SET_PUBLIC_REP_OFFER", response.data.data);
            router.push({ name: "showRepresentator", params: { id: newAcesss.get("token") }});
          }else{
            dispatch("Notify_Pop", response);
          }
        })
    },
    async userAdd({ dispatch, commit }, data) {
      commit("LOAD_LOGIN", true);
      await icp_request
        .post("user/company/add", data, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
          commit("LOAD_LOGIN", false);
          if (response.data) {
            dispatch("Notify_Pop", response);
          }
        })
    },
    async addAttribution({ dispatch }, data) {
      await icp_request
        .post("session/attribution/add", data, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
          if (response.data) {
            dispatch("Notify_Pop", response);
            dispatch("loadAllAttribution");
          }
        })
    },
    async addContract({ dispatch }, data) {
      await icp_request
        .post("session/add/contrat", data, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
          if (response.data) {
            dispatch("Notify_Pop", response);
            dispatch("loadAllContract");
          }
        })
    },
    async contractFileAdd({ dispatch }, data) {
      await icp_request
        .post("session/contract/files/add", data, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
          if (response.data) {
            dispatch("Notify_Pop", response);
            dispatch("loadAllContract");
          }
        })
    },
    async addProcuration({ dispatch }, data) {
      await icp_request
        .post("session/procuration/add", data, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
          if (response.data) {
            dispatch("Notify_Pop", response);
            //dispatch("getRecourse", data.get("recourse_id"));
          }
        })
    },
    async addSignature({ dispatch }, data) {
      await icp_request
        .post("session/signature/add", data, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
          if (response.data) {
            dispatch("Notify_Pop", response);
            dispatch("getSession", data.get("session_id"));
          }
        })
    },
    async sync_soPV(_, data) {
      await icp_request
        .post("session/pv/sync", data, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        });
    },
    async sync_attrPV(_, data) {
      await icp_request
        .post("session/attribution/pv/sync", data, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        });
    },
    async sync_evalPV(_, data) {
      await icp_request
        .post("session/eval/pv/sync", data, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        });
    },
    async sync_contractPV(_, data) {
      await icp_request
        .post("session/contract/sync", data, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        });
    },
    async get_sync_soPV({ commit }, id) {
      await icp_request
        .get("session/pv/sync/get/"+ id, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
          if (response.data) {
            commit("SET_SYNCSO", response.data);
          }
        });
    },
    async get_sync_evalPV({ commit }, id) {
      await icp_request
        .get("session/eval/pv/sync/"+ id, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
          if (response.data) {
            commit("SET_SYNCEVAL", response.data);
          }
        });
    },
    async get_sync_attrPV({ commit }, id) {
      await icp_request
        .get("session/attribution/pv/sync/get/"+ id, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
          if (response.data) {
            commit("SET_SYNCATTR", response.data);
          }
        });
    },
    async get_sync_contractPV({ commit }, id) {
      await icp_request
        .get("session/contract/sync/get/"+ id, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
          if (response.data) {
            commit("SET_SYNCCONTRACT", response.data);
          }
        });
    },
    async addSignatureContract({ dispatch }, data) {
      await icp_request
        .post("session/contract/signature/add", data, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
          if (response.data) {
            dispatch("Notify_Pop", response);
            dispatch("getContract",data.get("contract_id"));
          }
        })
    },
    async addAttrSignature({ dispatch }, data) {
      await icp_request
        .post("session/attribution/signature/add", data, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
          if (response.data) {
            dispatch("Notify_Pop", response);
            dispatch("getAttribution", data.get("attribution_id"));
          }
        })
    },
    async addEvalSignature({ dispatch }, data) {
      await icp_request
        .post("eval/signature/add", data, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
          if (response.data) {
            dispatch("Notify_Pop", response);
            dispatch("getEval",data.get('eval_id'));
          }
        })
    },
    async financialPropAdd({ dispatch }, data) {
      await icp_request
        .post("session/financial_props/open", data, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
          if (response.data) {
            dispatch("Notify_Pop", response);
            router.push({ name: "welcomeSession" });
          }
        })
    },
    async financialFinAdd({ dispatch }, data) {
      await icp_request
        .post("session/financial_props/add", data, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
          if (response.data) {
            dispatch("Notify_Pop", response);
            router.push({ name: "welcomeEvaluation" });
          }
        })
    },
    // async comContractCall({ dispatch }, data) {
    //   await icp_request
    //     .post("session/contract/com/call", data, {
    //       headers: { "Content-Type": "application/x-www-form-urlencoded" }
    //     })
    //     .then(response => {
    //       if (response.data) {
    //         dispatch("Notify_Pop", response);
    //         dispatch("loadAllSession");
    //       }
    //     })
    // },
    async comCall({ dispatch }, data) {
      await icp_request
        .post("commission/com/call", data, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
          if (response.data) {
            dispatch("Notify_Pop", response);
            dispatch("loadAllSession");
          }
        })
    },
    async DocAccessRequest({ dispatch }, data) {
      await icp_request
        .post("doc/access/request/folder_id", data, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
          if (response.data) {
            dispatch("Notify_Pop", response);
          }
        })
    },
    async ActiveDocToUser({ dispatch }, data) {
      await icp_request
        .post("doc/activate", data, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
          if (response.data) {
            dispatch("Notify_Pop", response);
            dispatch("loadAllAccessRequest",data.get('doc_id'));
          }
        })
    },
    async requestAdd({ dispatch }, data) {
      await icp_request
        .post("request/add", data, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
          if (response.data) {
            dispatch("Notify_Pop", response);
          }
        })
    },
    async userAdd2({ dispatch, commit }, data) {
      commit("LOAD_LOGIN", true);
      await icp_request
        .post("user/person/add", data, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
          commit("LOAD_LOGIN", false);
          if (response.data) {
            dispatch("Notify_Pop", response);
          }
        })
    },
    async logIn({ dispatch, commit }, credentials) {
      commit("LOAD_LOGIN", true);
      await icp_request
        .post("authenticate", credentials, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
          commit("LOAD_LOGIN", false);
          if (response.data) {
            if (response.data.data) {
              //console.log(response.data);
              if (response.status) {
                // window.location.reload();
                // console.log(response.data.data);
                dispatch("attempt", response.data.data.token);
                commit("SET_USER", response.data.data.user);
                dispatch("getData");
                if (response.data.data.user.profile.status == 2) {
                  router.push({ name: "Accueil" });
                } else {
                  router.push({ name: "Dashboard" });
                }
              }
            } else {
              dispatch("attempt", null);
              commit("SET_USER", null);
              Swal.fire({
                title: "Attention!",
                text: response.data.message,
                icon: "error",
                confirmButtonText: "OK"
              });
            }
          }
        })
    },
    async logOut({ commit }) {
      commit("LOG_OUT");
      commit("SET_USER", null);
      commit("SET_TOKEN", null);
      await localStorage.clear();
      router.push({ name: "Accueil" });
    },
    async signIn({ dispatch, commit }, credentials) {
      commit("LOAD_SIGNUP", true);
      await icp_request
        .post("public/company/add", credentials, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
          // console.log(response.data);
          commit("LOAD_SIGNUP", false);
          if (response.status) {
            router.push({ name: "Connexion" });
          }
          if (response.data) {
            dispatch("Notify_Pop", response);
          }
        })
    },
    async signInInd({ dispatch, commit }, credentials) {
      commit("LOAD_SIGNUP", true);
      await icp_request
        .post("public/person/add", credentials, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
          commit("LOAD_SIGNUP", false);
          if (response.data) {
            dispatch("Notify_Pop", response);
          }
        })
    },
    async addPPM({ dispatch, commit }, ppm) {
      commit("LOAD_PPM", true);
      await icp_request
        .post("ppm/add", ppm, {
          headers: { "Content-Type": "multipart/form-data" }
        })
        .then(response => {
          if (response.data) {
            const cur_year = new Date().getFullYear();
            commit("LOAD_PPM", false);
            commit("CLOSE_PPM", false);
            dispatch("Notify_Pop", response);
            dispatch("loadAllPPM", cur_year);
          }
        })
    },
    async addHomeDocs({ dispatch }, home_doc) {
      await icp_request
        .post("config/home/docs/add", home_doc, {
          headers: { "Content-Type": "multipart/form-data" }
        })
        .then(response => {
          if (response.data) {
            // commit("LOAD_PPM", false);
            // commit('CLOSE_PPM', false);
            dispatch("getAllConfigHome");
            dispatch("Notify_Pop", response);
            // dispatch("loadAllPPM");
          }
        })
    },
    async RemoveOffer({ dispatch }, offer) {
      await icp_request
        .post("offer/remove", offer, {
          headers: { "Content-Type": "multipart/form-data" }
        })
        .then(response => {
          if (response.data) {
            dispatch("Notify_Pop", response);
            dispatch("loadAllOffer");
            router.push({ name: "welcomeOffers" });
          }
        })
    },
    async UpdateOffer({ dispatch }, offer) {
      await icp_request
        .put("offer/update", offer, {
          headers: { 'Content-Type': 'application/json' , "Access-Control-Allow-Origin": '*'}
        })
        .then(response => {
          if (response.data) {
            dispatch("Notify_Pop", response);
            dispatch("getOffer", offer.get("offer_id"));
          }
        })
    },
    async addOffer({ dispatch }, offer) {
      await icp_request
        .post("offer/add", offer, {
          headers: { "Content-Type": "multipart/form-data" }
        })
        .then(response => {
          if (response.data) {
            dispatch("Notify_Pop", response);
            dispatch("loadAllOffer");
          }
        })
    },
    async updateDpFile({ dispatch }, file) {
      await icp_request
        .post("offer/dp/update", file, {
          headers: { "Content-Type": "multipart/form-data" }
        })
        .then(response => {
          if (response.data) {
            dispatch("Notify_Pop", response);
            dispatch("loadAllOffer");
            router.push({ name: "welcomeOffers" });
          }
        })
    },
    async addRecourseResp({ dispatch }, recourse) {
      await icp_request
        .post("recourse/response/add", recourse, {
          headers: { "Content-Type": "multipart/form-data" }
        })
        .then(response => {
          if (response.data) {
            dispatch("Notify_Pop", response);
            dispatch("getRecourse", recourse.get("recourse_id"));
            dispatch("loadAllRecourse");
          }
        })
    },
    async getHomeChart({ commit }) {
      // commit("LOAD_PPM", true);
      await icp_request
        .get("public/config/chart/get/all", {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
          if (response.data) {
            // console.log(container)
            // commit("LOAD_PPM", false);
            // commit('CLOSE_PPM', false);
            commit("SET_HOME_DOCS", response.data);
            // dispatch("loadAllPPM");
          }
        })
    },
    async getAllConfigHome({ commit }) {
      // commit("LOAD_PPM", true);
      await icp_request
        .get("public/config/info/get/all")
        .then(response => {
          if (response) {
            // console.log(container)
            // console.log(response.data);
            // commit("LOAD_PPM", false);
            // commit('CLOSE_PPM', false);
            commit("SET_ALL_CONFIG_HOME", response.data);
            // dispatch("loadAllPPM");
          }
        })
    },
    async getAllRemovedOffer({ commit },id) {
      // commit("LOAD_PPM", true);
      await icp_request
        .get("offer/remove/list/" + id)
        .then(response => {
          if (response) {
            // console.log(container)
            // console.log(response.data);
            // commit("LOAD_PPM", false);
            // commit('CLOSE_PPM', false);
            commit("SET_ALL_REMOVED_OFFER", response.data.data);
            // dispatch("loadAllPPM");
          }
        })
    },
    async loadAllRecourse({ commit }) {
      // commit("LOAD_PPM", true);
      await icp_request
        .get("recourse/all/get")
        .then(response => {
          if (response) {
            commit("SET_ALL_RECOURSE", response.data);
          }
        })
    },
    async loadAllSignature({ dispatch , commit }, id) {
      await icp_request
        .get("session/signature/get/all/"+ id)
        .then(response => {
          if (response) {
            dispatch("Notify_Pop", response);
            commit("SET_ALL_SIGNATURE", response.data);
          }
        })
    },
    async loadContractSignature({ dispatch }, id) {
      await icp_request
        .get("session/contract/signature/get/all/"+ id)
        .then(response => {
          if (response) {
            dispatch("Notify_Pop", response);
          }
        })
    },
    async loadEvalSignature({ dispatch , commit }, id) {
      await icp_request
        .get("eval/signature/get/all/"+ id)
        .then(response => {
          if (response) {
            dispatch("Notify_Pop", response);
            commit("SET_EVAL_SIGNATURE", response.data);
            //console.log('response',response.data.data.data)
          }
        })
    },
    async loadAllAccessRequest({ commit },id) {
      await icp_request
        .get("doc/access/request/get/all/"+ id)
        .then(response => {
          if (response) {
            commit("SET_ALL_ACCESS_REQUEST", response.data.data.data);
          }
        })
    },
    async postHomeInfo({ dispatch }, info) {

      await icp_request
        .post("public/config/info/add", info, {
          // "X-Requested-With":"XMLHttpRequest",
          headers: { "Content-Type": "application/json" }
        })
        .then(response => {
          // console.log(info);
          if (response.data) {
            // console.log(response.data);
            // commit("LOAD_PPM", false);
            // commit('CLOSE_PPM', false);
            dispatch("Notify_Pop", response);
            // dispatch("loadAllPPM");
          }
        })
    },
    async addNotice({ dispatch, commit }, notice) {
      commit("LOAD_NOTICE", true);
      await icp_request
        .post("notice/add", notice, {
          headers: { "Content-Type": "multipart/form-data" }
        })
        .then(response => {
          if (response.data) {
            commit("LOAD_NOTICE", false);
            commit("OPEN_AVIS", false);
            dispatch("Notify_Pop", response);
            dispatch("loadAllNotice");
            dispatch("loadPublicNotice");
          }
        })
    },
    async addDoc({ dispatch, commit }, doc) {
      commit("LOAD_DOC", true);
      await icp_request
        .post("doc/add", doc, {
          headers: { "Content-Type": "multipart/form-data"
           }
        })
        .then(response => {
          if (response.data) {
            commit("LOAD_DOC", false);
            commit("OPEN_DOC", false);
            dispatch("Notify_Pop", response);
            dispatch("loadAllDoc");
            dispatch("loadPublicNotice");
          }
        })
    },
    async downloadNotice({ dispatch }, notice) {
      // commit("LOAD_DOC", true);
      await icp_request
        .get("notice/download/"+ notice, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
          dispatch("Notify_Pop", response);
          //const toDownload = response.data.data;
          // if(toDownload.fileLink){
          //   console.log(toDownload);
          //   fileDownload(toDownload, toDownload.fileName);
          // };
        })
    },
    async downloadPpm({ dispatch }, ppm) {

      await icp_request
        .post("ppm/download", ppm, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
          dispatch("Notify_Pop", response);
          // const toDownload = response.data.data;
          // if(toDownload.file_link){
          //   fileDownload(toDownload, toDownload.file_name);
          // }
        })
    },
    async downloadDoc({ dispatch }, doc) {
      // commit("LOAD_DOC", true);
      // qconsole.log(notice)
      await icp_request
        .post("doc/download", doc, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
          dispatch("Notify_Pop", response);
          // const toDownload = response.data.data;
          // let name = toDownload.file_name;
          // dispatch("Notify_Pop", response);
          // if(toDownload.file_link){
          //   fileDownload(toDownload.file_blob, toDownload.file_name);
          // }
        })
    },
    async loadAllNotice({ commit }) {
      await icp_request
        .get("public/notice/all/get", {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
          const avis = [];
          // commit('LOAD_SIGNIN', false)
          // console.log(response);
          if (response.data) {
            response.data.data.forEach(value => {
              avis.unshift(value);
            });
            var filtered = avis.filter(function(value, index, arr){
              return value.publiDate < new Date()
            });
            commit("SET_LIST_AVIS", filtered);
          }
        })
    },
    async loadAllModel({ commit }) {
      await icp_request
        .get("pv/rapport_pv_model/get/all", {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
          const offer = [];
          if (response.data) {
            response.data.data.data.forEach(value => {
              offer.unshift(value);
            });
            commit("SET_LIST_MODEL", offer);
          }
        })
    },
    async loadAllOffer({ commit }) {
      await icp_request
        .get("offer/all/get", {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
          const offer = [];
          // commit('LOAD_SIGNIN', false)
          // console.log(response);
          if (response.data) {
            response.data.data.forEach(value => {
              offer.unshift(value);
            });
            commit("SET_LIST_OFFER", offer);
          }
        })
    },
    async loadAllMeeting({ commit }) {
      await icp_request
        .get("meeting/all/get", {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
          const meetings = [];
          // commit('LOAD_SIGNIN', false)
          // console.log(response);
          if (response.data) {
            response.data.data.forEach(value => {
              meetings.unshift(value);
            });
            commit("SET_LIST_MEETING", meetings);
          }
        })
    },
    async loadAllEvaluation({ commit }) {
      await icp_request
        .get("eval/all/get", {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
          const meetings = [];
          // commit('LOAD_SIGNIN', false)
          // console.log(response);
          if (response.data) {
            response.data.data.forEach(value => {
              meetings.unshift(value);
            });
            commit("SET_LIST_EVALUATION", meetings);
          }
        })
    },
    async loadAllSession({ commit }) {
      await icp_request
        .get("session/all/get", {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
          const session = [];
          // commit('LOAD_SIGNIN', false)
          // console.log(response);
          if (response.data) {
            response.data.data.forEach(value => {
              session.unshift(value);
            });
            commit("SET_LIST_SESSION", session);
          }
        })
    },
    async loadAllAttribution({ commit }) {
      await icp_request
        .get("session/attribution/all/get", {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
          const session = [];
          if (response.data) {
            response.data.data.forEach(value => {
              session.unshift(value);
            });
            commit("SET_LIST_ATTRIBUTION", session);
          }
        })
    },
    async loadAllContract({ commit }) {
      await icp_request
        .get("session/icp/contract/all/get", {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
          const session = [];
          if (response.data) {
            response.data.data.forEach(value => {
              session.unshift(value);
            });
            commit("SET_LIST_CONTRACT", session);
          }
        })
    },
    /* by kante */
    async getContractByUser({ commit }) {
      await icp_request
        .get("session/icp/contract/all", {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
          if (response.data) {
            commit("SET_SELECTED_USER_CONTRACT", response.data.data);
          }
        })
    },
     /* by kante */
    async loadAllVisit({ commit }) {
      await icp_request
        .get("visit/all/get", {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
          const visits = [];
          // commit('LOAD_SIGNIN', false)
          // console.log(response);
          if (response.data) {
            response.data.data.forEach(value => {
              visits.unshift(value);
            });
            commit("SET_LIST_VISIT", visits);
          }
        })
    },
    async loadAllRequest({ commit }) {
      await icp_request
        .get("request/all/get", {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
          const request = [];
          if (response.data) {
            response.data.data.forEach(value => {
              request.unshift(value);
            });
            commit("SET_LIST_REQUEST", request);
          }
        })
    },
    async loadAllComUser({ commit }) {
      await icp_request
        .get("commission/user/all/get", {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
          const user = [];
          // commit('LOAD_SIGNIN', false)
          // console.log(response);
          if (response.data) {
            response.data.data.forEach(value => {
              user.unshift(value);
            });
            commit("SET_COM_USER_LIST", user);
          }
        })
    },
    async loadNoticeTdr({ commit }, id) {
      await icp_request
        .get("notice/extension/all/get/" + id, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
          const tdr = [];
          // commit('LOAD_SIGNIN', false)
          if (response.data) {
            response.data.data.forEach(value => {
              tdr.unshift(value);
            });
            commit("SET_LIST_TDR", tdr);
          }
        })
    },
    async loadDocAdd({ commit }, id) {
      await icp_request
        .get("doc/extension/all/get/" + id, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
          const doc = [];
          // commit('LOAD_SIGNIN', false)
          if (response.data) {
            response.data.data.forEach(value => {
              doc.unshift(value);
            });
            commit("SET_LIST_ADD", doc);
          }
        })
    },
    async user_access_doc({ commit }, id) {
      await icp_request
        .get("doc/user_access_doc/" + id, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
          if (response.data) {
              commit("SET_USER_ACCESS_DOC", response.data);
          }
        })
    },
    async loadPublicNotice({ commit }) {
      await icp_request
        .get("public/notice/all/get", {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
          const notice = [];
          if (response.data) {
            response.data.data.forEach(value => {
              notice.unshift(value);
            });
            var filtered = notice.filter(function(value, index, arr){
              return value.publiDate < new Date()
            });
            commit("SET_PUBLIC_NOTICE", filtered);
          }
        })
    },
    async loadPublicNoticeType({ commit },{type, variant}) {
      if(variant){
        await icp_request
        .get("public/notice/all/get", {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
          const notice = [];
          if (response.data) {
            response.data.data.forEach(value => {
              if(value.noticeType.status == variant || value.noticeType.status == type){
                notice.unshift(value);
              }
            });
            var filtered = notice.filter(function(value, index, arr){
              return value.publiDate < new Date()
            });
             commit("SET_PUBLIC_NOTICE_TYPE", filtered);
          }
        })
      }else{
        await icp_request
        .get("public/notice/type/" + type, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
          const notice = [];
          if (response.data) {
            response.data.data.forEach(value => {
              notice.unshift(value);
            });
            var filtered = notice.filter(function(value, index, arr){
              return value.publiDate < new Date()
            });
            commit("SET_PUBLIC_NOTICE_TYPE", filtered);
          }
        })
      }
    },
    async loadPublicDocs({ commit }) {
      await icp_request
        .get("public/doc/all/get", {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
          const notice = [];
          if (response.data) {
            response.data.data.forEach(value => {
              notice.unshift(value);
            });
            var filtered = notice.filter(function(value, index, arr){
              return value.publiDate < new Date()
            });
            commit("SET_PUBLIC_DOC", filtered);
          }
        })
    },
   
    async loadAllPPM({ commit }, years) {
      await icp_request
        .get("ppm/public/all/get/year/" + years, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
          const ppm = [];
          if (response.data) {
            response.data.data.forEach(value => {
              ppm.unshift(value);
            });
            commit("SET_LIST_PPM", ppm);
          }
        })
    },

    async loadAllDoc({ commit }) {
      await icp_request
        .get("doc/all/get", {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
          const docs = [];
          if (response.data) {
            response.data.data.forEach(value => {
              docs.unshift(value);
            });
            var filtered = docs.filter(function(value, index, arr){
              return value.publiDate < new Date();
            });
            commit("SET_LIST_DOC", filtered);
          }
        })
    },
    async loadAllMessage({ commit }) {
      await icp_request
        .get("msg/get/all", {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
          const mess = [];
          if (response.data) {
            response.data.data.message_list.forEach(value => {
              mess.unshift(value);
            });
            commit("SET_MESSAGE_LIST", mess);
          }
        })
    },
    async getPPM({ commit}, id) {
      //vm.$wait.start('my list is to load');
      await icp_request
        .get("ppm/get/" + id, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
          // commit('LOAD_SIGNIN', false)
          if (response.data) {
            commit("SET_SELECTED_PPM", response.data.data);
            // dispatch("Notify_Pop", response);
            // response.data.data
          }
          //vm.$wait.end('my list is to load');
        })
    },
    async getRecourse({ commit}, id) {
      await icp_request
        .get("recourse/get/" + id, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
          // commit('LOAD_SIGNIN', false)
          if (response.data) {
            commit("SET_SELECTED_RECOURSE", response.data.data);
            // dispatch("Notify_Pop", response);
            // response.data.data
          }
        })
    },
    async getPPMRev({ commit }, id) {
      await icp_request
        .get("ppm/file/get/" + id, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
          switch (response.data.status) {
            case 0:
              //  console.log(response.data)
              commit("SET_SELECTED_PPM_REV", response.data.data);
              break;
            case -1:
              dispatch("Notify_Pop", response);
              commit("SET_SELECTED_PPM_REV", "");
              break;
            default:
              break;
          }
        })
    },

    async getNotice({ commit }, id) {
      await icp_request
        .get("public/notice/get/" + id, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
          // commit('LOAD_SIGNIN', false)
          if (response.data) {
            commit("SET_SELECTED_NOTICE", response.data.data);
            // dispatch("Notify_Pop", response);
            // response.data.data
          }
        })
    },
    async getOffer({ commit }, id) {
      await icp_request
        .get("offer/get/" + id, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
          // commit('LOAD_SIGNIN', false)
          if (response.data) {
            commit("SET_SELECTED_OFFER", response.data.data);
            // dispatch("Notify_Pop", response);
            // response.data.data
          }
        })
    },
    async getRequest({ commit }, id) {
      await icp_request
        .get("request/get/" + id, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
          if (response.data) {
            commit("SET_SELECTED_REQUEST", response.data.data);
          }
        })
    },
    async getAttendanceList({ commit }, id) {
      await icp_request
        .get("session/attendance/list/" + id, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
          if (response.data) {
            commit("SET_ATTENDANCE_LIST", response.data.data);
          }
        })
    },
    async getAllPostponement({ commit }) {
      await icp_request
        .get("session/user/postponement/all", {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
          if (response.data) {
            commit("SET_POSTPONEMENT_LIST", response.data.data);
          }
        })
    },
    async getRequestResponse({ dispatch, commit }, id) {
      await icp_request
        .get("response/get/" + id, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
          switch (response.data.status) {
            case 0:
              commit("SET_SELECTED_REQUEST_RESPONSE", response.data.data);
              break;
            case -1:
              dispatch("Notify_Pop", response);
              commit("SET_SELECTED_REQUEST_RESPONSE", "");
              router.push({ name: "request" });
              break;
            default:
              break;
          }
          // if (response.data.status == 0) {
          //     commit("SET_SELECTED_REQUEST_RESPONSE", response.data.data);
          // }else{
          //     console.log(response.data);
          //     dispatch("Notify_Pop", response.data.message);
          //     commit("SET_SELECTED_REQUEST_RESPONSE", '');
          // }
        })
    },

    async getDoc({ commit }, id) {
      await icp_request
        .get("doc/get/" + id, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
          // commit('LOAD_SIGNIN', false)
          if (response.data) {
            commit("SET_SELECTED_DOC", response.data.data);
            // dispatch("Notify_Pop", response);
            // response.data.data
          }
        })
    },
    async getMeeting({ commit }, id) {
      await icp_request
        .get("meeting/get/" + id, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
          // commit('LOAD_SIGNIN', false)
          if (response.data) {
            commit("SET_SELECTED_MEETING", response.data.data.meeting);
            // dispatch("Notify_Pop", response);
            // response.data.data
          }
        })
    },
    async getMeetingPv({ commit }, id) {
      await icp_request
        .get("meeting/get/" + id, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
          // commit('LOAD_SIGNIN', false)
          if (response.data) {
              commit("SET_SELECTED_MEETING_PV", response.data.data.pv);
            // dispatch("Notify_Pop", response);
            // response.data.data
          }
        })
    },
    async getSession({ commit }, id) {
      await icp_request
        .get("session/get/" + id, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
          // commit('LOAD_SIGNIN', false)
          if (response.data) {
            commit("SET_SELECTED_SESSION", response.data.data);
            // dispatch("Notify_Pop", response);
            // response.data.data
          }
        })
    },
    async getModelPv({ commit }, id) {
      await icp_request
        .get("pv/rapport_pv_model/" + id, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
          // commit('LOAD_SIGNIN', false)
          if (response.data) {
            commit("SET_SELECTED_MODELPV", response.data.data);
            // dispatch("Notify_Pop", response);
            // response.data.data
          }
        })
    },
    async getModelPvBySession({ commit }, id) {
      await icp_request
        .get("pv/rapport_pv_model/session/" + id, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
          // commit('LOAD_SIGNIN', false)
          if (response.data) {
            commit("SET_SELECTED_MODELPVBYSESSION", response.data.data.rapportPvs);
            // dispatch("Notify_Pop", response);
            // response.data.data
          }
        })
    },
    async getSessionListPresence({ commit }, id) {
      await icp_request
        .get("session/list/presence?session_id=" + id, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
          // commit('LOAD_SIGNIN', false)
          if (response.data) {
            commit("SET_SELECTED_SESSION_LIST_PRESENCE", response.data.data);
            // dispatch("Notify_Pop", response);
            // response.data.data
          }
        })
    },
    async getContract({ commit }, id) {
      await icp_request
        .get("session/contract/get/" + id, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
          // commit('LOAD_SIGNIN', false)
          if (response.data) {
            commit("SET_SELECTED_CONTRACT", response.data.data);
            // dispatch("Notify_Pop", response);
            // response.data.data
          }
        })
    },
    async getAttribution({ commit }, id) {
      await icp_request
        .get("session/attribution/get/" + id, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
          // commit('LOAD_SIGNIN', false)
          if (response.data) {
            commit("SET_SELECTED_ATTRIBUTION", response.data.data);
            // dispatch("Notify_Pop", response);
            // response.data.data
          }
        })
    },
    async getEval({ commit }, id) {
      await icp_request
        .get("eval/get/" + id, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
          // commit('LOAD_SIGNIN', false)
          if (response.data) {
            commit("SET_SELECTED_EVALUATION", response.data.data);
            // dispatch("Notify_Pop", response);
            // response.data.data
          }
        })
    },
    async getVisit({ commit }, id) {
      await icp_request
        .get("visit/get/" + id, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
          // commit('LOAD_SIGNIN', false)
          if (response.data) {
            commit("SET_SELECTED_VISIT", response.data.data.visit);
            // dispatch("Notify_Pop", response);
            // response.data.data
          }
        })
    },
    async getVisitPv({ commit }, id) {
      await icp_request
        .get("visit/get/" + id, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
          // commit('LOAD_SIGNIN', false)
          if (response.data) {
              commit("SET_SELECTED_VISIT_PV", response.data.data.pv);
            // dispatch("Notify_Pop", response);
            // response.data.data
          }
        })
    },
    async ppmFileAdd({ dispatch, commit }, ppmFileAdd) {
      // const id = ppmFileAdd.get("ppm_id");
      commit("LOAD_PPMREVADD", true);
      await icp_request
        .post("ppm/file/add", ppmFileAdd, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
          if (response.data) {
            //console.log(ppmFileAdd.get("ppm_id"));
            dispatch("Notify_Pop", response);
            commit("LOAD_PPMREVADD", false);
            dispatch("getAllppmRev", ppmFileAdd.get("ppm_id"));
            dispatch("getPPM", ppmFileAdd.get("ppm_id"));
          }
        })
    },
    async addResponseToRequest({ dispatch }, response) {
      // const id = ppmFileAdd.get("ppm_id");
      // commit('LOAD_PPMREVADD', true);
      await icp_request
        .post("response/add", response, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
          if (response.data) {
            // console.log(response.data);
            dispatch("Notify_Pop", response);
            // commit('LOAD_PPMREVADD', false);
            // console.log(ppmFileAdd.get("ppm_id"));
            // dispatch("getAllppmRev",ppmFileAdd.get("ppm_id"));
          }
        })
    },
    async addVisitPresence({ dispatch }, id) {
      // const id = ppmFileAdd.get("ppm_id");
      // commit('LOAD_PPMREVADD', true);
      await icp_request
        .post("visit/presence/add", id, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
          if (response.data) {
            // console.log(response.data);
            dispatch("Notify_Pop", response);
          }
        })
    },
    async addMeetingPresence({ dispatch }, id) {
      // const id = ppmFileAdd.get("ppm_id");
      // commit('LOAD_PPMREVADD', true);
      await icp_request
        .post("meeting/presence/add", id, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
          if (response.data) {
            // console.log(response.data);
            dispatch("Notify_Pop", response);
          }
        })
    },
    async addMeeting({ dispatch }, meeting) {
      // const id = ppmFileAdd.get("ppm_id");
      // commit('LOAD_PPMREVADD', true);
      await icp_request
        .post("meeting/create_meeting", meeting, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
          if (response.data) {
            // console.log(response.data);
            dispatch("Notify_Pop", response);
            dispatch("loadAllMeeting");
            // commit('LOAD_PPMREVADD', false);
            // console.log(ppmFileAdd.get("ppm_id"));
            // dispatch("getAllppmRev",ppmFileAdd.get("ppm_id"));
          }
        })
    },
    async addEvaluation({ dispatch }, evaluation) {
      // const id = ppmFileAdd.get("ppm_id");
      // commit('LOAD_PPMREVADD', true);
      await icp_request
        .post("eval/add", evaluation, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
          if (response.data) {
            // console.log(response.data);
            dispatch("Notify_Pop", response);
            dispatch("loadAllEvaluation");
            // commit('LOAD_PPMREVADD', false);
            // console.log(ppmFileAdd.get("ppm_id"));
            // dispatch("getAllppmRev",ppmFileAdd.get("ppm_id"));
          }
        })
    },
    async UpdateModelPv({ dispatch }, session) {
      await icp_request
        .post("pv/rapport_pv_model/update", session, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
          if (response.data) {
            // console.log(response.data);
            dispatch("Notify_Pop", response);
            dispatch("getModelPv",session.get('id_model'));
            // commit('LOAD_PPMREVADD', false);
            // console.log(ppmFileAdd.get("ppm_id"));
            // dispatch("getAllppmRev",ppmFileAdd.get("ppm_id"));
          }
        })
    },
    async addModel({ dispatch }, session) {
      await icp_request
        .post("pv/rapport_pv_model/add", session, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
          if (response.data) {
            // console.log(response.data);
            dispatch("Notify_Pop", response);
            dispatch("loadAllModel");
            // commit('LOAD_PPMREVADD', false);
            // console.log(ppmFileAdd.get("ppm_id"));
            // dispatch("getAllppmRev",ppmFileAdd.get("ppm_id"));
          }
        })
    },
    async addSession({ dispatch }, session) {
      // const id = ppmFileAdd.get("ppm_id");
      // commit('LOAD_PPMREVADD', true);
      await icp_request
        .post("session/add", session, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
          if (response.data) {
            // console.log(response.data);
            dispatch("Notify_Pop", response);
            dispatch("loadAllSession");
            // commit('LOAD_PPMREVADD', false);
            // console.log(ppmFileAdd.get("ppm_id"));
            // dispatch("getAllppmRev",ppmFileAdd.get("ppm_id"));
          }
        })
    },
    async addPV({ dispatch }, pv) {
      if(pv.get("open_session")){
        await icp_request
        .post("pv/add", pv, {
          headers: { "Content-Type": "multipart/form-data" }
        })
        .then(response => {
          if (response.data) {
            dispatch("getSession",pv.get("open_session"));
            dispatch("Notify_Pop", response);
          }
        })
      }
      if(pv.get("attribution_id")){
        await icp_request
        .post("session/attribution/pv/add", pv, {
          headers: { "Content-Type": "multipart/form-data" }
        })
        .then(response => {
          if (response.data) {
            dispatch("getAttribution",pv.get("attribution_id"));
            dispatch("Notify_Pop", response);
          }
        })
      }
      if(pv.get("eval_id")){
        await icp_request
        .post("session/eval/pv/add", pv, {
          headers: { "Content-Type": "multipart/form-data" }
        })
        .then(response => {
          if (response.data) {
            dispatch("getEval",pv.get("eval_id"));
            dispatch("Notify_Pop", response);
          }
        })
      }
      if(pv.get("contract_id")){
        await icp_request
        .post("session/contract/pv/add", pv, {
          headers: { "Content-Type": "multipart/form-data" }
        })
        .then(response => {
          if (response.data) {
            dispatch("getContract",pv.get("contract_id"));
            dispatch("Notify_Pop", response);
          }
        })
      }
      if(pv.get("meeting")){
        await icp_request
        .post("pv/add", pv, {
          headers: { "Content-Type": "multipart/form-data" }
        })
        .then(response => {
          if (response.data) {
            dispatch("getMeetingPv",pv.get("meeting"));
            dispatch("Notify_Pop", response);
          }
        })
      }
      if(pv.get("visit")){
        await icp_request
        .post("pv/add", pv, {
          headers: { "Content-Type": "multipart/form-data" }
        })
        .then(response => {
          if (response.data) {
            dispatch("getVisitPv",pv.get("visit"));
            dispatch("Notify_Pop", response);
          }
        })
      }
    },
    async addVisit({ dispatch }, visit) {
      // const id = ppmFileAdd.get("ppm_id");
      // commit('LOAD_PPMREVADD', true);
      await icp_request
        .post("visit/add", visit, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
          if (response.data) {
            // console.log(response.data);
            dispatch("Notify_Pop", response);
            dispatch("loadAllVisit");
            // commit('LOAD_PPMREVADD', false);
            // console.log(ppmFileAdd.get("ppm_id"));
            // dispatch("getAllppmRev",ppmFileAdd.get("ppm_id"));
          }
        })
    },
    async addMemeberChart({ dispatch }, [value, member]) {
      // const id = ppmFileAdd.get("ppm_id");
      // commit('LOAD_PPMREVADD', true);
      // console.log(value);
      await icp_request
        .post(
          "public/config/chart_data/add/" + value,
          {
            name: member.name,
            title: member.title
          },
          {
            headers: { "Content-Type": "application/json" }
          }
        )
        .then(response => {
          if (response.data) {
            // this.$store.dispatch('Auth/getAllConfigHome');
            // console.log(response.data);
            dispatch("Notify_Pop", response);
            dispatch("getAllConfigHome");
            dispatch("GetAllMembers");
            dispatch("getAllConfigHome");
          }
        })
    },
    async GetAllMembers({ commit }) {
      // console.log(value);
      await icp_request
        .get("public/config/chart/get/all")
        .then(response => {
          if (response.data) {
            commit("SET_CHART_MEMBERS", response.data.data);
          }
        })
    },
    async send_message({ dispatch }, message) {
      // const id = ppmFileAdd.get("ppm_id");
      // commit('LOAD_PPMREVADD', true);
      await icp_request
        .post("public/msg/add", message, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
          if (response.data) {
            // console.log(response.data);
            dispatch("Notify_Pop", response);
            // commit('LOAD_PPMREVADD', false);
            // console.log(ppmFileAdd.get("ppm_id"));
            dispatch("loadAllMessage");
          }
        })
    },
    async send_message_reply({ dispatch }, message) {
      // const id = ppmFileAdd.get("ppm_id");
      // commit('LOAD_PPMREVADD', true);
      await icp_request
        .post("msg/response/add", message, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
          if (response.data) {
            // console.log(response.data);
            dispatch("Notify_Pop", response);
            // commit('LOAD_PPMREVADD', false);
            // console.log(ppmFileAdd.get("ppm_id"));
            dispatch("loadAllMessage");
          }
        })
    },
    async getSubmissionFoldNoti({ commit }) {
      await icp_request
        .get("doc/submission_folders_notice/get/all", {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
          // let com = [];
          if (response.data) {
            // response.data.data.forEach((value) => {
            //     com.unshift(value);
            // });
            //console.log(response.data.data.docs.concat(response.data.data.notice_ami_apq))
            commit("SET_SubmissionFoldNoti_LIST", response.data.data);
          }
        })
    },
    async loadAllPv({ commit }) {
      await icp_request
        .get("pv/all/get", {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
          // let com = [];
          if (response.data) {
            // response.data.data.forEach((value) => {
            //     com.unshift(value);
            // });
            commit("SET_PV_LIST", response.data.data);
          }
        })
    },

    async loadAllCom({ commit }) {
      await icp_request
        .get("commission/all/get", {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
          // let com = [];
          if (response.data) {
            // response.data.data.forEach((value) => {
            //     com.unshift(value);
            // });
            commit("SET_COM_LIST", response.data.data);
          }
        })
    },
    async loadComById({ commit }, id) {
      await icp_request
        .get("commission/get/" + id, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
          if (response.data) {
            commit("SET_SELECTED_COM", response.data.data);
          }
        })
    },
    async getOfferKeys({ commit }, id) {
      await icp_request
        .get("offer/access_code/all/get/" + id, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
          if (response.data) {
            commit("SET_OFFER_KEYS", response.data.data);
          }
        })
    },
    async loadListPresence({ commit }, id) {
      await icp_request
        .get("visit/presence/list/" + id, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
          if (response.data) {
            commit("SET_LIST_VISIT_PRESENCE", response.data.data);
          }
        })
    },
    async loadListPresenceMeeting({ commit }, id) {
      await icp_request
        .get("meeting/presence/list/" + id, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
          if (response.data) {
            commit("SET_LIST_MEETING_PRESENCE", response.data.data);
          }
        })
    },
    async resetChart({ dispatch }) {
      // const id = ppmFileAdd.get("ppm_id");
      // commit('LOAD_PPMREVADD', true);
      await icp_request
        .post("public/config/chart_data/reset", {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
          if (response.data) {
            dispatch("Notify_Pop", response);
          }
        })
    },
    async add_com({ dispatch }, com) {
      // const id = ppmFileAdd.get("ppm_id");
      // commit('LOAD_PPMREVADD', true);
      await icp_request
        .post("commission/add", com, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
          if (response.data) {
            // console.log(response.data);
            dispatch("Notify_Pop", response);
            // commit('LOAD_PPMREVADD', false);
            // console.log(ppmFileAdd.get("ppm_id"));
            dispatch("loadAllCom");
          }
        })  
    },
    async add_com_member({ dispatch }, member) {
      await icp_request
        .post("commission/member/add", member, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
          if (response.data) {
            dispatch("Notify_Pop", response);
            dispatch("loadComById",member.get("commission"));
          }
        })
    },
    async addReport_doc({ dispatch }, date) {
      await icp_request
        .post("doc/update_date", date, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
          if (response.data) {
            dispatch("Notify_Pop", response);
            dispatch("getDoc",date.get("doc_id"));
          }
        })
    },
    async addLetter_doc({ dispatch }, letter) {
      // const id = ppmFileAdd.get("ppm_id");
      // commit('LOAD_PPMREVADD', true);
      await icp_request
        .post("doc/send/doc/user", letter, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
          if (response.data) {
            dispatch("Notify_Pop", response);
          }
        })
    },
    async extension_addN({ dispatch }, notice) {
      // const id = ppmFileAdd.get("ppm_id");
      // commit('LOAD_PPMREVADD', true);
      await icp_request
        .post("notice/extension/add", notice, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
          if (response.data) {
            dispatch("Notify_Pop", response);
            dispatch("getNotice", notice.get("notice"));
            dispatch("loadNoticeTdr", notice.get("notice"));
          }
        })
    },
    async addRecourse({ dispatch }, recourse) {
      // const id = ppmFileAdd.get("ppm_id");
      // commit('LOAD_PPMREVADD', true);
      await icp_request
        .post("recourse/add", recourse , {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
          if (response.data) {
            dispatch("Notify_Pop", response);
            // commit('LOAD_PPMREVADD', false);
            // console.log(ppmFileAdd.get("ppm_id"));
            dispatch("loadAllRecourse");
          }
        })
    },
    async extension_addD({ dispatch }, document) {
      // const id = ppmFileAdd.get("ppm_id");
      // commit('LOAD_PPMREVADD', true);
      await icp_request
        .post("doc/extension/add", document, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
          if (response.data) {
            dispatch("Notify_Pop", response);
            // commit('LOAD_PPMREVADD', false);
            // console.log(ppmFileAdd.get("ppm_id"));
            // dispatch("loadAllMessage");
            dispatch("loadDocAdd", document.get("document"));
          }
        })
    },
    async getAllppmRev({ commit }, id) {
      await icp_request
        .get("ppm/file/all/get/" + id, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
          // commit('LOAD_SIGNIN', false)
          if (response.data) {
            const list = [];
            response.data.data.forEach(value => {
              list.unshift(value);
            });
            commit("SET_REV_PPM", list);
            // dispatch("Notify_Pop", response);
            // response.data.data
          }
        })
    }
  }
};
