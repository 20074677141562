import axios from 'axios';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import "sweetalert2/src/sweetalert2.scss";
import NProgress from 'vue-nprogress';
const nprogress = new NProgress();
import {store} from '../store/index';

import axiosRetry from 'axios-retry';

export const icp_request = axios.create({
  // baseURL: "http://192.168.1.101:8080/icp/"
  // baseURL: "https://eprocurement.cfapps.io/"
  // baseURL: "https://icp-back.herokuapp.com/icp/"
  // baseURL: "https://icp-env.eba-kupren92.us-east-2.elasticbeanstalk.com/icp/"
  // "baseURL": "https://www.back-icp.ml/icp/"
  // "baseURL": "http://localhost:5000/icp"
  //baseURL: "http://icpapp-env.eba-pgm2irch.us-east-2.elasticbeanstalk.com/icp/"
  //baseURL: "http://icp-env.eba-sn9zvxen.us-east-1.elasticbeanstalk.com/icp/"
  // baseURL: "https://e-procurement-icp.herokuapp.com/icp/"
  // baseURL: "https://0d52-41-82-175-222.ngrok.io/icp/"

  // baseURL: "http://78.138.45.147:5000/icp/"
  baseURL: "https://api.icp-e-procurement.com/icp/"
});


// https://github.com/softonic/axios-retry/issues/87
const retryDelay = (retryNumber = 0) => {
  const seconds = Math.pow(2, retryNumber) * 1000;
  const randomMs = 1000 * Math.random();
  return seconds + randomMs;
};

axiosRetry(icp_request, {
  retries: 2,
  retryDelay,
  // retry on Network Error & 5xx responses
  retryCondition: axiosRetry.isRetryableError,
});

nprogress.configure({ showSpinner: true });

icp_request.interceptors.request.use(
  (config) => {
    nprogress.start();
    return config;
  },
  async (error) => {
    //console.log("RSP-1 ",error);
    nprogress.done();
    nprogress.remove();
    return Promise.reject(error);
  }
);

icp_request.interceptors.response.use(
  (response) => {
    //console.log("RSP-2 ",response.data);
    nprogress.done();
    nprogress.remove();

    return response;
  },
  async (error) => {
    //console.log("RSP- ",error);
    nprogress.done();
    nprogress.remove();
    if (error.response) {
      switch (error.response.status) {
        case 400:
          Swal.fire({
            title: "Veuillez vérifier si tous les champs ont bien été remplis avant de programmer la visite de site.",
            text: "Erreur!",
            icon: "error",
          });
          break;

        case 401:
          Swal.fire({
            title: "Votre session a éxpiré - Veuillez vous reconnecter !",
            text: "Erreur 401 !",
            icon: "error",
          });
          break;
        case 403:
          Swal.fire({
            title: "Le serveur n'autorise pas l'accées !",
            text: "Erreur 403 !",
            icon: "error",
          });
          break;
        case 404:
          Swal.fire({
            title: "La page demandé n'existe pas !",
            text: "Erreur 404 !",
            icon: "error",
          });
          break;
      }
      return Promise.reject(error.response);
    }
  }
);
/* icp_request.interceptors.response.use(
  response => {
    if (response.status === 200 || response.status === 201) {

    } else {

    }
  },
  error => {
    if (error.response.status) {
      switch (error.response.status) {
        case 400:
          Swal.fire({
            title: "Votre requête est mal formulée !",
            text: "Erreur 400 !",
            icon: "error",
          });
          break;

        case 401:
          Swal.fire({
            title: "Votre session a éxpiré - Veuillez vous reconnecter !",
            text: "Erreur 401 !",
            icon: "error",
          });
          break;
        case 403:
          Swal.fire({
            title: "Le serveur n'autorise pas l'accées !",
            text: "Erreur 403 !",
            icon: "error",
          });
          break;
        case 404:
          Swal.fire({
            title: "La page demandé n'existe pas !",
            text: "Erreur 404 !",
            icon: "error",
          });
          break;
      }
      return Promise.reject(error.response);
    }
  }
);
 */
