import Vue from "vue";
import VueRouter from "vue-router";
import { store } from '../store/index';

Vue.use(VueRouter);

//HOME PAGE VISIBLE FOR ALL CANDIDATES

const Home = () => import(/* webpackChunkName: "home" */'../views/Home.vue');
const Connexion = () => import(/* webpackChunkName: "home" */'../views/Registration.vue');
const Registration = () => import(/* webpackChunkName: "home" */'../views/Connexion.vue');
const PasswordReset = () => import(/* webpackChunkName: "home" */'../views/PasswordReset/index.vue');
const NotFound = () => import(/* webpackChunkName: "home" */'../components/NotFound');
const Contact = () => import(/* webpackChunkName: "home" */'../views/Contact.vue');

//DASHBOARD ONLY FOR SUBSCRIBED MEMBERS OPERATOR COM AC ADMIN

const Dashboard = () => import(/* webpackChunkName: "dashboard" */'../views/dashboard');
const RapidAccess = () => import(/* webpackChunkName: "dashboard" */'../views/dashboard/pages/Rapid_access.vue');


//ADMIN ROUTES
const UserAdd = () => import(/* webpackChunkName: "admin" */'../views/dashboard/pages/admin/UserAdd.vue');
const All_users = () => import(/* webpackChunkName: "admin" */'../views/dashboard/pages/admin/all_users');
const showAllUsers = () => import(/* webpackChunkName: "admin" */'../views/dashboard/pages/admin/all_users/show.vue');
const welcomeAllUsers = () => import(/* webpackChunkName: "admin" */'../views/dashboard/pages/admin/all_users/welcome.vue');
const Access_management = () => import(/* webpackChunkName: "admin" */'../views/dashboard/pages/admin/Accessmanagement.vue');

const visit = () => import(/* webpackChunkName: "visit" */'../views/dashboard/pages/visit');
const showVisit = () => import(/* webpackChunkName: "visit" */'../views/dashboard/pages/visit/show.vue');
const welcomeVisit = () => import(/* webpackChunkName: "visit" */'../views/dashboard/pages/visit/welcome.vue');

const meeting = () => import(/* webpackChunkName: "meeting" */'../views/dashboard/pages/meeting');
const showMeeting = () => import(/* webpackChunkName: "meeting" */'../views/dashboard/pages/meeting/show.vue');
const welcomeMeeting = () => import(/* webpackChunkName: "meeting" */'../views/dashboard/pages/meeting/welcome.vue');

const representator = () => import(/* webpackChunkName: "representator" */'../views/dashboard/pages/representator');
const showRepresentator = () => import(/* webpackChunkName: "representator" */'../views/dashboard/pages/representator/show.vue');
const welcomeRepresentator = () => import(/* webpackChunkName: "representator" */'../views/dashboard/pages/representator/welcome.vue');

const Config = () => import(/* webpackChunkName: "config" */'../views/config');
const confCall = () => import('../views/config/ConfCall.vue');
const message = () => import(/* webpackChunkName: "message" */'../views/dashboard/pages/message');

// -------------------------------------------------------- DASHBOARD

// ----------------- ADMIN GLOBAL ROUTES

// ----------------- AC GLOBAL ROUTES

// PPM ROUTES
const ppm = () => import(/* webpackChunkName: "ppm" */'../views/dashboard/pages/ppm');
const showPpm = () => import(/* webpackChunkName: "ppm" */'../views/dashboard/pages/ppm/show.vue');
const welcomePpm = () => import(/* webpackChunkName: "ppm" */'../views/dashboard/pages/ppm/welcome.vue');

// RECOURSE
const Recourse = () => import(/* webpackChunkName: "recourse" */'../views/Public/recourse');
const showRecourse = () => import(/* webpackChunkName: "recourse" */'../views/Public/recourse/show.vue');
const welcomeRecourse = () => import(/* webpackChunkName: "recourse" */'../views/Public/recourse/welcome.vue');

// FOLDER ROUTES
const folder = () => import(/* webpackChunkName: "folder" */'../views/dashboard/pages/folder');
const welcomeFolder = () => import(/* webpackChunkName: "folder" */'../views/dashboard/pages/folder/welcome.vue');
const showFolder = () => import(/* webpackChunkName: "folder" */'../views/dashboard/pages/folder/show.vue');

// NOTICE ROUTES
const notice = () => import(/* webpackChunkName: "notice" */'../views/dashboard/pages/notice');
const showNotice = () => import(/* webpackChunkName: "notice" */'../views/dashboard/pages/notice/show.vue');
const welcomeNotice = () => import(/* webpackChunkName: "notice" */'../views/dashboard/pages/notice/welcome.vue');

// COMMISSION ROUTES
const commission = () => import(/* webpackChunkName: "commission" */'../views/dashboard/pages/commission');
const welcomeCommission = () => import(/* webpackChunkName: "commission" */'../views/dashboard/pages/commission/welcome');
const showCommission = () => import(/* webpackChunkName: "commission" */'../views/dashboard/pages/commission/show');

// OPERATOR FOLDERS ROUTES
const OpFolder = () => import(/* webpackChunkName: "opfolder" */'../views/dashboard/pages/op/folder');
const welcomeOpFolder = () => import(/* webpackChunkName: "opfolder" */'../views/dashboard/pages/op/folder/welcome.vue');
const showOpFolder = () => import(/* webpackChunkName: "opfolder" */'../views/dashboard/pages/op/folder/show.vue');
const showGiveAccess = () => import(/* webpackChunkName: "opfolder" */'../views/dashboard/pages/op/folder/showGiveAccess.vue');

// REQUEST ROUTES
const request = () => import(/* webpackChunkName: "request" */'../views/dashboard/pages/request');
const welcomeRequest = () => import(/* webpackChunkName: "request" */'../views/dashboard/pages/request/welcome.vue');
const showRequest = () => import(/* webpackChunkName: "request" */'../views/dashboard/pages/request/show.vue');
const showRequestResponse = () => import(/* webpackChunkName: "request" */'../views/dashboard/pages/request/showResponse.vue');

// OFFERS ROUTES
const Offers = () => import(/* webpackChunkName: "offer" */'../views/dashboard/pages/offers');
const showOffers = () => import(/* webpackChunkName: "offer" */'../views/dashboard/pages/offers/show.vue');
const welcomeOffers = () => import(/* webpackChunkName: "offer" */'../views/dashboard/pages/offers/welcome.vue');
const requestOffers = () => import(/* webpackChunkName: "offer" */'../views/dashboard/pages/offers/request');

const Session = () => import(/* webpackChunkName: "session" */'../views/dashboard/pages/session');
const showSession = () => import(/* webpackChunkName: "session" */'../views/dashboard/pages/session/show.vue');
const welcomeSession = () => import(/* webpackChunkName: "session" */'../views/dashboard/pages/session/welcome.vue');

const Model_pv = () => import(/* webpackChunkName: "pv" */'../views/dashboard/pages/model_pv');
const showModel_pv = () => import(/* webpackChunkName: "pv" */'../views/dashboard/pages/model_pv/show.vue');
const welcomeModel_pv = () => import(/* webpackChunkName: "pv" */'../views/dashboard/pages/model_pv/welcome.vue');

const Attribution = () => import(/* webpackChunkName: "attribution" */'../views/dashboard/pages/attribution');
const showAttribution = () => import(/* webpackChunkName: "attribution" */'../views/dashboard/pages/attribution/show.vue');
const welcomeAttribution = () => import(/* webpackChunkName: "attribution" */'../views/dashboard/pages/attribution/welcome.vue');

const Contract = () => import(/* webpackChunkName: "contract" */'../views/dashboard/pages/contract');
const showContract = () => import(/* webpackChunkName: "contract" */'../views/dashboard/pages/contract/show.vue');
const welcomeContract = () => import(/* webpackChunkName: "contract" */'../views/dashboard/pages/contract/welcome.vue');

const evaluation = () => import(/* webpackChunkName: "evaluation" */'../views/dashboard/pages/evaluation');
const showEvaluation = () => import(/* webpackChunkName: "evaluation" */'../views/dashboard/pages/evaluation/show.vue');
const welcomeEvaluation = () => import(/* webpackChunkName: "evaluation" */'../views/dashboard/pages/evaluation/welcome.vue');

// ----------------- OPERATOR GLOBAL ROUTES

// -------------------------------------------------------- HOME

// PUBLIC NOTICE ROUTES

const show_p_ppm = () => import(/* webpackChunkName: "publicppm" */'../views/Public/ppm/show.vue');
const public_ppm = () => import(/* webpackChunkName: "publicppm" */'../views/Public/ppm');
const welcome_ppm_public = () => import(/* webpackChunkName: "publicppm" */'../views/Public/ppm/welcome.vue');

const public_notice = () => import(/* webpackChunkName: "publicnotice" */'../views/Public/notice');
const show_p_notice = () => import(/* webpackChunkName: "publicnotice" */'../views/Public/notice/show.vue');
const welcome_p_notice = () => import(/* webpackChunkName: "publicnotice" */'../views/Public/notice/welcome.vue');

const noticeType1 = () => import(/* webpackChunkName: "publicnotice" */'../views/Public/notice/noticeType/1.vue');
const noticeType2 = () => import(/* webpackChunkName: "publicnotice" */'../views/Public/notice/noticeType/2.vue');
const noticeType3 = () => import(/* webpackChunkName: "publicnotice" */'../views/Public/notice/noticeType/3.vue');
const noticeType4 = () => import(/* webpackChunkName: "publicnotice" */'../views/Public/notice/noticeType/4.vue');
const noticeType5 = () => import(/* webpackChunkName: "publicnotice" */'../views/Public/notice/noticeType/5.vue');
const noticeType6 = () => import(/* webpackChunkName: "publicnotice" */'../views/Public/notice/noticeType/6.vue');


// Fonction pour sécurisée une route si l 'utilisateur n'a pas de session active

function guardAdmin(to, from, next) {
  // Verification du token
  if (localStorage.getItem('icp_pass') && store.getters['Auth/isActive']) {
    next();
  } else {
    next({ name: "Connexion" });
  }
}
function guardClient(to, from, next) {
  // Verification du token
  if (localStorage.getItem('icp_pass') && store.getters['Auth/isActive']) {
    next({ name: "Dashboard" });
  } else {
    next();
  }
}

const routes = [
  {
    path: "/",
    name: "Accueil",
    component: Home,
    meta: {
      title: 'Accueil | ICP-E-PROCUREMENT',
      metaTags: [
        {
          name: 'description',
          content: "La passation des marchés publics est l'une des activités gouvernementales les plus vulnérables à la corruption. Outre le volume des transactions et les intérêts financiers en jeu, comme rappelé ci-dessus, les risques de corruption "
        },
        {
          property: 'og:description',
          content: "La passation des marchés publics est l'une des activités gouvernementales les plus vulnérables à la corruption. Outre le volume des transactions et les intérêts financiers en jeu, comme rappelé ci-dessus, les risques de corruption "
        }
      ]
    }
  },
  {
    path: "/connexion",
    name: "Connexion",
    component: Connexion,
    beforeEnter: guardClient,
    meta: {
      title: 'Connexion | ICP-E-PROCUREMENT',
      metaTags: [
        {
          name: 'description',
          content: "Page pour s'authentifier at acceder à son espace."
        },
        {
          property: 'og:description',
          content: "Page pour s'authentifier at acceder à son espace."
        }
      ]
    }
  },
  {
    path: "/registration",
    name: "Registration",
    component: Registration,
    beforeEnter: guardClient,
    meta: {
      title: 'Inscription | ICP-E-PROCUREMENT',
      metaTags: [
        {
          name: 'description',
          content: "Page pour la création de compte "
        },
        {
          property: 'og:description',
          content: "Page pour la création de compte"
        }
      ]
    }
  },
  {
    path: "/dashboard",
    component: Dashboard,
    name: "Dashboard",
    meta: {
      title: 'Tableau de bord | ICP-E-PROCUREMENT',
      metaTags: [
        {
          name: 'description',
          content: "Espace sécurisée"
        },
        {
          property: 'og:description',
          content: "Espace sécurisée"
        }
      ],
      breadcrumb: 'Tableau de bord'
    },
    redirect: { name: "RapidAccess" },
    beforeEnter: guardAdmin,
    children: [
      {
        path: "visit",
        name: "visit",
        component: visit,
        meta: {
          title: 'Visite de site | ICP-E-PROCUREMENT',
          metaTags: [
            {
              name: 'description',
              content: "Les visites de sites"
            },
            {
              property: 'og:description',
              content: "Les visites de sites"
            }
          ],
          breadcrumb: 'Liste des visites de site '
        },
        redirect: { name: "welcomeVisit" },
        children: [
          {
            path: "",
            name: "welcomeVisit",
            component: welcomeVisit
          },
          {
            meta: {
              breadcrumb: 'Aperçu de la visite de site'
            },
            path: ":id",
            name: "showVisit",
            props: true,
            component: showVisit
          }
        ]
      },
      {
        path: "evaluation",
        name: "Evaluation",
        component: evaluation,
        meta: {
          title: 'Evaluation | ICP-E-PROCUREMENT',
          metaTags: [
            {
              name: 'description',
              content: "Les évaluations"
            },
            {
              property: 'og:description',
              content: "Les évaluations"
            }
          ],
          breadcrumb: 'LISTE DES SEANCES D’EVALUATIONS DES OFFRES/PROPOSITIONS/PLIS '
        },
        redirect: { name: "welcomeEvaluation" },
        children: [
          {
            path: "",
            name: "welcomeEvaluation",
            component: welcomeEvaluation
          },
          {
            meta: {
              breadcrumb: 'Aperçu de la séance d’évaluation des Offres/Propositions/Plis/Manifestations d’Intérêts/Pré-qualifications '
            },
            path: ":id",
            name: "showEvaluation",
            props: true,
            component: showEvaluation
          }
        ]
      },
      {
        path: "meeting",
        name: "meeting",
        component: meeting,
        meta: {
          title: 'Conférence Préalable | ICP-E-PROCUREMENT',
          metaTags: [
            {
              name: 'description',
              content: "Les Conférences préalable"
            },
            {
              property: 'og:description',
              content: "Les Conférences préalable"
            }
          ],
          breadcrumb: 'Liste des Conférences préalables'
        },
        redirect: { name: "welcomeMeeting" },
        children: [
          {
            path: "",
            name: "welcomeMeeting",
            component: welcomeMeeting
          },
          {
            meta: {
              breadcrumb: 'Aperçu de la conférence préalable'
            },
            path: ":id",
            name: "showMeeting",
            props: true,
            component: showMeeting
          }
        ]
      },
      {
        name: "RapidAccess",
        path: "",
        component: RapidAccess
      },
      {
        meta: {
          title: 'Reunion en ligne | ICP-E-PROCUREMENT',
          metaTags: [
            {
              name: 'description',
              content: "Séance de Visio-conférence"
            },
            {
              property: 'og:description',
              content: "Séance de Visio-conférence"
            }
          ],
          breadcrumb: 'Séance de Visio-conférence'
        },
        name: "confCall",
        path: "confCall",
        component: confCall
      },
      {
        meta: {
          breadcrumb: 'Configuration des informations de la page d’accueil'
        },
        name: "Config",
        path: "config",
        component: Config
      },
      {
        path: "ppm",
        name: "ppm",
        component: ppm,
        meta: {
          breadcrumb: 'Liste des Plans de Passation des Marchés (PPM)'
        },
        redirect: { name: "welcomePpm" },
        children: [
          {
            path: "",
            name: "welcomePpm",
            component: welcomePpm,
          },
          {
            path: ":id",
            name: "showPpm",
            props: true,
            component: showPpm,
            meta: {
              breadcrumb: 'Aperçu du Plan de Passation des Marchés (PPM)'
            }
          }
        ]
      },
      {
        meta: {
          breadcrumb: 'Demandes de  renseignement'
        },
        path: "message",
        name: "message",
        component: message
      },
      {
        meta: {
          breadcrumb: 'Création de compte'
        },
        path: "UserAdd",
        name: "UserAdd",
        component: UserAdd
      },
      {
        meta: {
          breadcrumb: 'Gestion de compte'
        },
        path: "All_users",
        name: "All_users",
        component: All_users,
        redirect: { name: "welcomeAllUsers" },
        children: [
          {
            path: "",
            name: "welcomeAllUsers",
            component: welcomeAllUsers
          },
          {
            meta: {
              breadcrumb: 'Aperçu du compte'
            },
            path: ":id",
            name: "showAllUsers",
            props: true,
            component: showAllUsers
          }
        ]
      },
      {
        meta: {
          breadcrumb: 'Abréviation/Acronyme'
        },
        path: "Access_management",
        name: "Access_management",
        component: Access_management
      },
      {
        meta: {
          breadcrumb: 'Liste de Commissions'
        },
        path: "commission",
        name: "commission",
        component: commission,
        redirect: { name: "welcomeCommission" },
        children: [
          {
            path: "",
            name: "welcomeCommission",
            component: welcomeCommission
          },
          {
            meta: {
              breadcrumb: 'Aperçu et configuration des  membres de la commission'
            },
            path: ":id",
            name: "showCommission",
            props: true,
            component: showCommission
          }
        ]
      },
      {
        path: "notice",
        name: "notice",
        component: notice,
        meta: {
          breadcrumb: 'Liste des Avis'
        },
        redirect: { name: "welcomeNotice" },
        children: [
          {
            path: "",
            name: "welcomeNotice",
            component: welcomeNotice
          },
          {
            meta: {
              breadcrumb: 'Aperçu de l’avis publié'
            },
            path: ":id",
            name: "showNotice",
            props: true,
            component: showNotice
          }
        ]
      },
      {
        meta: {
          breadcrumb: 'CONSULTER ET TELECHARGER DES DEMANDES D’ECLAIRCISSEMENTS'
        },
        path: "request",
        name: "request",
        component: request,
        redirect: { name: "welcomeRequest" },
        children: [
          {
            path: "",
            name: "welcomeRequest",
            component: welcomeRequest
          },
          {
            meta: {
              breadcrumb: 'Aperçu de la demande d’éclaircissements'
            },
            path: ":id",
            name: "showRequest",
            props: true,
            component: showRequest,
          }
        ]
      },
      {
        meta: {
          breadcrumb: 'Réponse à la Demandes d’éclaircissement'
        },
        path: "response/:id",
        name: "showRequestResponse",
        props: true,
        component: showRequestResponse
      },
      {
        path: "OpFolder",
        name: "OpFolder",
        meta: {
          breadcrumb: 'LISTE DES DOSSIERS DE CONSULTATION'
        },
        component: OpFolder,
        redirect: { name: "welcomeOpFolder" },
        children: [
          {
            path: "",
            name: "welcomeOpFolder",
            component: welcomeOpFolder
          },
          {
            meta: {
              breadcrumb: 'Aperçu du dossier d’appel à la concurrence'
            },
            path: ":id",
            name: "showOpFolder",
            props: true,
            component: showOpFolder
          },
          {
            meta: {
              breadcrumb: 'Paramétrage de l’accès aux dossiers après paiement'
            },
            path: ":id",
            name: "showGiveAccess",
            props: true,
            component: showGiveAccess
          },
        ]
      },
      {
        path: "folder",
        name: "folder",
        component: folder,
        redirect: { name: "welcomeFolder" },
        meta: {
          breadcrumb: 'LISTE DES DOSSIERS DE CONSULTATION'
        },
        children: [
          {
            path: "",
            name: "welcomeFolder",
            component: welcomeFolder
          },
          {
            meta: {
              breadcrumb: 'Aperçu du dossier d’appel à la concurrence'
            },
            path: ":id",
            name: "showFolder",
            props: true,
            component: showFolder
          }
        ]
      },
      {
        meta: {
          breadcrumb: "Liste des Offre/Propositions/Réponses aux manifestations d'intérêt ou avis de pré-qualification"
        },
        path: "Offers",
        name: "Offers",
        component: Offers,
        redirect: { name: "welcomeOffers" },
        children: [
          {
            path: "",
            name: "welcomeOffers",
            component: welcomeOffers
          },
          {
            meta: {
              breadcrumb: "Aperçu de l' Offre/Proposition/Pli"
            },
            path: ":id",
            name: "showOffers",
            props: true,
            component: showOffers
          }
        ]
      },
      {
        meta: {
          breadcrumb: "LISTE DES SEANCES PUBLIQUES D’OUVERTURES"
        },
        path: "Session",
        name: "Session",
        component: Session,
        redirect: { name: "welcomeSession" },
        children: [
          {
            path: "",
            name: "welcomeSession",
            component: welcomeSession
          },
          {
            meta: {
              breadcrumb: "Aperçu de la séance publique d’ouverture des Offres/Propositions/Plis"
            },
            path: ":id",
            name: "showSession",
            props: true,
            component: showSession
          }
        ]
      },
      {
        meta: {
          breadcrumb: "Création, modification et mise à jour des modèles de dossiers"
        },
        path: "Model_pv",
        name: "Model_pv",
        component: Model_pv,
        redirect: { name: "welcomeModel_pv" },
        children: [
          {
            path: "",
            name: "welcomeModel_pv",
            component: welcomeModel_pv
          },
          {
            meta: {
              breadcrumb: "Page de redaction"
            },
            path: ":id",
            name: "showModel_pv",
            props: true,
            component: showModel_pv
          }
        ]
      },
      {
        meta: {
          breadcrumb: "LISTE DES SÉANCES PLÉNIÈRES D’ATTRIBUTIONS DES MARCHES"
        },
        path: "Attribution",
        name: "Attribution",
        component: Attribution,
        redirect: { name: "welcomeAttribution" },
        children: [
          {
            path: "",
            name: "welcomeAttribution",
            component: welcomeAttribution
          },
          {
            meta: {
              breadcrumb: "Aperçu de la séance plénière d’attribution du marché"
            },
            path: ":id",
            name: "showAttribution",
            props: true,
            component: showAttribution
          }
        ]
      },
      {
        meta: {
          breadcrumb: "Liste des séances de negociation"
        },
        path: "Contract",
        name: "Contract",
        component: Contract,
        redirect: { name: "welcomeContract" },
        children: [
          {
            path: "",
            name: "welcomeContract",
            component: welcomeContract
          },
          {
            meta: {
              breadcrumb: "Aperçu de la séance de négociation du marché"
            },
            path: ":id",
            name: "showContract",
            props: true,
            component: showContract
          }
        ]
      },
      {
        meta: {
          breadcrumb: 'Demandes'
        },
        path: "/request",
        name: "request",
        component: requestOffers
      }
    ]
  },
  {
    path: "/representator",
    name: "representator",
    component: representator,
    meta: {
      breadcrumb: 'Vérification par le système du code d’accès du représentant du soumissionnaire à la séance publique d’ouverture (Offres/ Propositions/ Plis)'
    },
    redirect: { name: "welcomeRepresentator" },
    children: [
      {
        path: "",
        name: "welcomeRepresentator",
        component: welcomeRepresentator
      },
      {
        meta: {
          breadcrumb: 'Espace du représentant du soumissionnaire pour la participation à la séance publique d’ouverture (Offres/ Propositions/ Plis)'
        },
        path: ":id",
        name: "showRepresentator",
        props: true,
        component: showRepresentator
      }
    ]
  },
  {
    path: "/password/reset/:token",
    name: "Password_Reset",
    props: true,
    component: PasswordReset,
    meta: {
      title: 'Reinitialisation mot de passe | ICP-E-PROCUREMENT',
      metaTags: [
        {
          name: 'description',
          content: "Reinitialisation de votre mot de passe"
        },
        {
          property: 'og:description',
          content: "Reinitialisation de votre mot de passe"
        }
      ],
      breadcrumb: 'Reinitialisation de votre mot de passe'
    },
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact
  },
  {
    meta: {
      breadcrumb: 'Liste des recours'
    },
    beforeEnter: guardAdmin,
    path: "/recourse",
    name: "recourse",
    component: Recourse,
    redirect: { name: "welcomeRecourse" },
    children: [
      {
        path: "",
        name: "welcomeRecourse",
        component: welcomeRecourse
      },
      {
        meta: {
          breadcrumb: 'Aperçu Recours'
        },
        path: ":id",
        name: "showRecourse",
        props: true,
        component: showRecourse
      }
    ]
  },
  {
    meta: {
      breadcrumb: 'Liste des avis'
    },
    path: "/public_notice",
    name: "public_notice",
    component: public_notice,
    redirect: { name: "welcome_p_notice" },
    children: [
      {
        path: "",
        name: "welcome_p_notice",
        component: welcome_p_notice
      },
      {
        meta: {
          breadcrumb: 'Aperçu de l’avis publié'
        },
        path: ":id",
        name: "show_p_notice",
        props: true,
        component: show_p_notice
      },
        {
          path: "type/AGPM",
          name: "show_p_notice_by_type1",
          component: noticeType1
        },
        {
          path: "type/AMI",
          name: "show_p_notice_by_type2",
          component: noticeType2
        },
        {
          path: "type/APQ",
          name: "show_p_notice_by_type3",
          component: noticeType3
        },
        {
          path: "type/AAO",
          name: "show_p_notice_by_type4",
          component: noticeType4
        },
        {
          path: "type/ADP",
          name: "show_p_notice_by_type5",
          component: noticeType5
        },
        {
          path: "type/ADD",
          name: "show_p_notice_by_type6",
          component: noticeType6
        }
    ]
  },
  {
    path: "/public_ppm",
    name: "public_ppm",
    component: public_ppm,
    redirect: { name: "welcome_ppm_public" },
    meta: {
      breadcrumb: 'Liste des Plans de Passation des Marchés (PPM)'
    },
    children: [
      {
        path: "",
        name: "welcome_ppm_public",
        component: welcome_ppm_public,
      },
      {
        path: ":id",
        name: "show_p_ppm",
        props: true,
        meta: {
          breadcrumb: 'Aperçu du Plan de Passation des Marchés (PPM)'
        },
        component: show_p_ppm
      }
    ],
  },
  {
    // matches everything else
    path: "*",
    name: "notFound",
    component: NotFound
  }
];

const router = new VueRouter({
  mode: "history",
  scrollBehavior: () => ({ x: 0, y: 0 }),
  base: process.env.BASE_URL,
  routes
});

// ...

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if(nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if(previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if(!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
  // Add the meta tags to the document head.
  .forEach(tag => document.head.appendChild(tag));

  next();
});

// ...

export default router;
