import Vue from 'vue';
// import plugin
import { TiptapVuetifyPlugin } from 'tiptap-vuetify';
import App from './App.vue';
import VOffline from 'v-offline';

import 'tiptap-vuetify/dist/main.css';
import 'moment/locale/fr';
import VueMoment from 'vue-moment';
import moment from 'moment-timezone';
import VueFriendlyIframe from 'vue-friendly-iframe';
import VueStripeMenu from 'vue-stripe-menu';
import 'vue-stripe-menu/dist/vue-stripe-menu.css';
import VueBreadcrumbs from 'vue-breadcrumbs';
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib';
import VueTour from 'vue-tour';

// import fancyBox from 'vue-fancybox';

// javascript import for when you're importing the css directly in your javascript
import 'vue-navigation-bar/dist/vue-navigation-bar.css';
// import the library
import VueNavigationBar from 'vue-navigation-bar';
import { store } from './store';
import VueSignaturePad from 'vue-signature-pad';
import router from './router';
import vuetify from './plugins/vuetify';
import vueWait from './plugins/vue-wait';
import NProgress from 'vue-nprogress'
require('vue-tour/dist/vue-tour.css');
import ElementUI from 'element-ui';
import { ElementTiptapPlugin } from 'element-tiptap';
// import ElementUI's styles
import 'element-ui/lib/theme-chalk/index.css';
// import this package's styles
import 'element-tiptap/lib/index.css';
import VueHtml2pdf from 'vue-html2pdf';

Vue.use(VueSignaturePad);

Vue.use(VueHtml2pdf);
// use ElementUI's plugin
Vue.use(ElementUI);
// use this package's plugin
Vue.use(ElementTiptapPlugin,  {
  lang: "fr",
  spellcheck: true,
});

const nprogress = new NProgress()
const options = {
  latencyThreshold: 180,
  router: true, // Show progressbar when navigating routes, default: true
  http: true // Show progressbar when doing Vue.http, default: true
};

Vue.use(NProgress,options);
Vue.use(VueTelInputVuetify, {
  vuetify,
});
Vue.use(VOffline);
Vue.use(VueTour);
Vue.use(TiptapVuetifyPlugin, {
  vuetify,
  iconsGroup: 'mdi',
});
Vue.use(VueStripeMenu);
Vue.use(VueFriendlyIframe);
Vue.use(VueBreadcrumbs);
Vue.component('vue-navigation-bar', VueNavigationBar);
Vue.use(VueMoment, { moment });

// Reauth
store.dispatch('Auth/attempt', localStorage.getItem('icp_pass'));
store.dispatch('Auth/getData');

Vue.config.productionTip = false;
new Vue({
  vuetify,
  router,
  store,
  moment,
  nprogress,
  VueFriendlyIframe,
  vueWait,
  render: h => h(App),
}).$mount('#app');
