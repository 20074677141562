var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{attrs:{"id":"inspire"}},[_c('v-responsive',{attrs:{"width":_vm.$vuetify.breakpoint.width}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(
        [
          'Connexion',
          'Registration',
          'Accueil',
          'welcomeRepresentator',
          'showRepresentator',
          'Password_Reset',
          'Contact',
          'welcome_ppm_public',
          'welcome_p_ppm',
          'show_p_notice',
          'welcome_p_notice',
          'welcome_p_folder',
          'show_p_folder',
          'show_p_ppm',
          'notFound',
          'showRecourse',
          'welcomeRecourse',
          'show_ppm_rev_public',
          'show_p_notice_by_type1',
          'show_p_notice_by_type2',
          'show_p_notice_by_type3',
          'show_p_notice_by_type4',
          'show_p_notice_by_type5',
          'show_p_notice_by_type6'
        ].indexOf(_vm.$route.name) > -1
      ),expression:"\n        [\n          'Connexion',\n          'Registration',\n          'Accueil',\n          'welcomeRepresentator',\n          'showRepresentator',\n          'Password_Reset',\n          'Contact',\n          'welcome_ppm_public',\n          'welcome_p_ppm',\n          'show_p_notice',\n          'welcome_p_notice',\n          'welcome_p_folder',\n          'show_p_folder',\n          'show_p_ppm',\n          'notFound',\n          'showRecourse',\n          'welcomeRecourse',\n          'show_ppm_rev_public',\n          'show_p_notice_by_type1',\n          'show_p_notice_by_type2',\n          'show_p_notice_by_type3',\n          'show_p_notice_by_type4',\n          'show_p_notice_by_type5',\n          'show_p_notice_by_type6'\n        ].indexOf($route.name) > -1\n      "}],staticClass:"fixed p-0",attrs:{"fixed":"","width":_vm.$vuetify.breakpoint.width}},[_c('vue-navigation-bar',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isActive),expression:"!isActive"}],staticClass:"navigation",attrs:{"options":_vm.navbarOptions}}),_c('vue-navigation-bar',{directives:[{name:"show",rawName:"v-show",value:(_vm.isActive),expression:"isActive"}],staticClass:"navigation",attrs:{"options":_vm.navbarOptions2}})],1),_c('detected-speed',{staticClass:"absolute",staticStyle:{"max-height":".35rem"},attrs:{"width":_vm.$vuetify.breakpoint.width}},[_c('div',{attrs:{"slot":"fast"},slot:"fast"},[_c('div',{staticClass:"online"},[_c('span',[_vm._v(" ")])])]),_c('div',{attrs:{"slot":"slow"},slot:"slow"},[_c('div',{staticClass:"offline"},[_c('span',[_vm._v(" ")])])])]),_c('v-snackbar',{scopedSlots:_vm._u([{key:"action",fn:function(ref){
      var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"pink","text":""},on:{"click":function($event){return _vm.accept()}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.showUpgradeUI),callback:function ($$v) {_vm.showUpgradeUI=$$v},expression:"showUpgradeUI"}},[_vm._v(" "+_vm._s(_vm.text)+" ")]),_c('nprogress-container'),_c('v-parallax',{directives:[{name:"show",rawName:"v-show",value:(['Accueil'].indexOf(_vm.$route.name) > -1),expression:"['Accueil'].indexOf($route.name) > -1"}],staticClass:"text-center",attrs:{"height":"260","width":_vm.$vuetify.breakpoint.width,"src":require('@/assets/profile.jpg')}},[_c('h1',[_vm._v("ICP-E-PROCUREMENT")]),_c('h3',{staticClass:"text-uppercase"},[_vm._v(" Plateforme de dématérialisation des marchés publics ")])]),_c('v-container',{staticClass:"fill-height",class:{
        'px-0 py-0': _vm.$vuetify.breakpoint.smAndDown,
        'px-4 py-4': _vm.$vuetify.breakpoint.mdAndUp
      },attrs:{"fluid":""}},[_c('v-row',{staticClass:"fill-height bg",attrs:{"align-center":"","justify-center":""}},[_c('v-col',[_c('v-fade-transition',{attrs:{"mode":"out-in"}},[_c('div',[_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(
                  [
                    'Password_Reset',
                    'welcome_p_notice',
                    'welcome_ppm_public',
                    'show_p_notice',
                    'show_p_ppm',
                    'welcomeRepresentator',
                    'showRepresentator',
                    'showRecourse',
                    'welcomeRecourse',
                    'show_ppm_rev_public',
                    'show_p_notice_by_type1',
                    'show_p_notice_by_type2',
                    'show_p_notice_by_type3',
                    'show_p_notice_by_type4',
                    'show_p_notice_by_type5',
                    'show_p_notice_by_type6'
                  ].indexOf(_vm.$route.name) > -1
                ),expression:"\n                  [\n                    'Password_Reset',\n                    'welcome_p_notice',\n                    'welcome_ppm_public',\n                    'show_p_notice',\n                    'show_p_ppm',\n                    'welcomeRepresentator',\n                    'showRepresentator',\n                    'showRecourse',\n                    'welcomeRecourse',\n                    'show_ppm_rev_public',\n                    'show_p_notice_by_type1',\n                    'show_p_notice_by_type2',\n                    'show_p_notice_by_type3',\n                    'show_p_notice_by_type4',\n                    'show_p_notice_by_type5',\n                    'show_p_notice_by_type6'\n                  ].indexOf($route.name) > -1\n                "}],staticClass:"my-1 ",staticStyle:{"background-color":"#f4f4f4"},attrs:{"justify":"left"}},[_c('v-breadcrumbs',{directives:[{name:"show",rawName:"v-show",value:(_vm.breadCrumbs),expression:"breadCrumbs"}],attrs:{"items":_vm.breadCrumbs,"divider":"►"},scopedSlots:_vm._u([{key:"item",fn:function(props){return [(!props.item.disabled)?_c('router-link',{attrs:{"to":props.item.href}},[_c('v-breadcrumbs-item',{class:[props.item.disabled && 'disabled']},[_vm._v(" "+_vm._s(props.item.text)+" ")])],1):_c('v-breadcrumbs-item',{class:[props.item.disabled && 'disabled']},[_vm._v(" "+_vm._s(props.item.text)+" ")])]}}])})],1),_c('router-view',{key:this.$route.path})],1)])],1)],1)],1),_c('v-app-footer',{directives:[{name:"show",rawName:"v-show",value:(
        [
          'Connexion',
          'Registration',
          'Accueil',
          'Password_Reset',
          'Contact',
          'welcome_p_notice',
          'welcome_ppm_public',
          'showRepresentator',
          'welcomeRepresentator',
          'show_p_notice',
          'show_p_ppm',
          'NotFound',
          'showRecourse',
          'welcomeRecourse',
          'show_ppm_rev_public'
        ].indexOf(_vm.$route.name) > -1
      ),expression:"\n        [\n          'Connexion',\n          'Registration',\n          'Accueil',\n          'Password_Reset',\n          'Contact',\n          'welcome_p_notice',\n          'welcome_ppm_public',\n          'showRepresentator',\n          'welcomeRepresentator',\n          'show_p_notice',\n          'show_p_ppm',\n          'NotFound',\n          'showRecourse',\n          'welcomeRecourse',\n          'show_ppm_rev_public'\n        ].indexOf($route.name) > -1\n      "}],staticClass:"mt-1",attrs:{"padless":"","width":_vm.$vuetify.breakpoint.width}},[_c('v-row',{attrs:{"justify":"center","no-gutters":""}},[_c('v-col',{staticClass:"primary py-4 text-center white--text",attrs:{"cols":"12"}},[_c('strong',[_vm._v("© E-procurement "+_vm._s(new Date().getFullYear())+" | v1")])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }