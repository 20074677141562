<template>
  <v-app id="inspire">
    <v-responsive :width="$vuetify.breakpoint.width">
      <div
        class="fixed p-0"
        fixed
        :width="$vuetify.breakpoint.width"
        v-show="
          [
            'Connexion',
            'Registration',
            'Accueil',
            'welcomeRepresentator',
            'showRepresentator',
            'Password_Reset',
            'Contact',
            'welcome_ppm_public',
            'welcome_p_ppm',
            'show_p_notice',
            'welcome_p_notice',
            'welcome_p_folder',
            'show_p_folder',
            'show_p_ppm',
            'notFound',
            'showRecourse',
            'welcomeRecourse',
            'show_ppm_rev_public',
            'show_p_notice_by_type1',
            'show_p_notice_by_type2',
            'show_p_notice_by_type3',
            'show_p_notice_by_type4',
            'show_p_notice_by_type5',
            'show_p_notice_by_type6'
          ].indexOf($route.name) > -1
        "
      >
        <vue-navigation-bar class="navigation" :options="navbarOptions" v-show="!isActive" />
        <vue-navigation-bar class="navigation" :options="navbarOptions2" v-show="isActive" />
      </div>
      <detected-speed style="max-height:.35rem" :width="$vuetify.breakpoint.width" class="absolute">
        <div slot="fast">
          <div class="online">
            <span>&nbsp;</span>
          </div>
        </div>
        <div slot="slow">
          <div class="offline">
            <span>&nbsp;</span>
          </div>
        </div>
      </detected-speed>
      <v-snackbar v-model="showUpgradeUI">
        {{ text }}

        <template v-slot:action="{ attrs }">
          <v-btn color="pink" text v-bind="attrs" @click="accept()">
            Close
          </v-btn>
        </template>
      </v-snackbar>
      <nprogress-container></nprogress-container>
      <!-- PARAPLAX HEADER -->
      <v-parallax
        v-show="['Accueil'].indexOf($route.name) > -1"
        class="text-center"
        height="260"
        :width="$vuetify.breakpoint.width"
        :src="require('@/assets/profile.jpg')"
      >
        <h1>ICP-E-PROCUREMENT</h1>
        <h3 class="text-uppercase">
          Plateforme de dématérialisation des marchés publics
        </h3>
      </v-parallax>
      <v-container
        :class="{
          'px-0 py-0': $vuetify.breakpoint.smAndDown,
          'px-4 py-4': $vuetify.breakpoint.mdAndUp
        }"
        class="fill-height"
        fluid
      >
        <v-row class="fill-height bg" align-center justify-center>
          <v-col>
            <v-fade-transition mode="out-in">
              <div>
                <v-row
                class="my-1 "
                style="background-color: #f4f4f4"
                  v-show="
                    [
                      'Password_Reset',
                      'welcome_p_notice',
                      'welcome_ppm_public',
                      'show_p_notice',
                      'show_p_ppm',
                      'welcomeRepresentator',
                      'showRepresentator',
                      'showRecourse',
                      'welcomeRecourse',
                      'show_ppm_rev_public',
                      'show_p_notice_by_type1',
                      'show_p_notice_by_type2',
                      'show_p_notice_by_type3',
                      'show_p_notice_by_type4',
                      'show_p_notice_by_type5',
                      'show_p_notice_by_type6'
                    ].indexOf($route.name) > -1
                  "
                  justify="left"
                >
                  <v-breadcrumbs v-show="breadCrumbs" :items="breadCrumbs" divider="►">
                    <template v-slot:item="props">
                      <router-link :to="props.item.href" v-if="!props.item.disabled">
                        <v-breadcrumbs-item :class="[props.item.disabled && 'disabled']">
                          {{ props.item.text }}
                        </v-breadcrumbs-item>
                      </router-link>
                      <v-breadcrumbs-item :class="[props.item.disabled && 'disabled']" v-else>
                        {{ props.item.text }}
                      </v-breadcrumbs-item>
                    </template>
                  </v-breadcrumbs>
                </v-row>
                <router-view :key="this.$route.path"></router-view>
              </div>
            </v-fade-transition>
          </v-col>
        </v-row>
      </v-container>
      <v-app-footer
        padless
        :width="$vuetify.breakpoint.width"
        class="mt-1"
        v-show="
          [
            'Connexion',
            'Registration',
            'Accueil',
            'Password_Reset',
            'Contact',
            'welcome_p_notice',
            'welcome_ppm_public',
            'showRepresentator',
            'welcomeRepresentator',
            'show_p_notice',
            'show_p_ppm',
            'NotFound',
            'showRecourse',
            'welcomeRecourse',
            'show_ppm_rev_public'
          ].indexOf($route.name) > -1
        "
      >
        <v-row justify="center" no-gutters>
          <v-col class="primary py-4 text-center white--text" cols="12">
            <strong>&copy; E-procurement {{ new Date().getFullYear() }} | v1</strong>
          </v-col>
        </v-row>
      </v-app-footer>
    </v-responsive>
  </v-app>
</template>

<script>
// import Navigation from './components/welcome/Navigation';
import { mapGetters } from "vuex";
import NprogressContainer from "vue-nprogress/src/NprogressContainer";
import { icp_request } from "./config/axios";

export default {
  components: {
    DetectedSpeed: () => import('vue-identify-network'),
    NprogressContainer: () => import('vue-nprogress/src/NprogressContainer'),
  },
  name: "App",

  computed: {
    ...mapGetters({
      isActive: "Auth/isActive"
    }),
    breadCrumbs() {
      let pathArray = this.$route.path.split("/");
      pathArray.shift();
      const breadCrumbs = [];
      // needed to handle the intermediary entries for nested vue routes
      let breadcrumb = "";
      let lastIndexFound = 0;
      for (let i = 0; i < pathArray.length; ++i) {
        breadcrumb = `${breadcrumb}${"/"}${pathArray[i]}`;
        console.log(
          this.$route.matched[i],
          Object.hasOwnProperty.call(this.$route.matched[i], "meta"),
          Object.hasOwnProperty.call(this.$route.matched[i].meta, "breadcrumb")
        );
        if (
          this.$route.matched[i] &&
          Object.hasOwnProperty.call(this.$route.matched[i], "meta") &&
          Object.hasOwnProperty.call(this.$route.matched[i].meta, "breadcrumb")
        ) {
          if (
            this.$route.matched[i].meta.title ===
              "Reinitialisation mot de passe | ICP-E-PROCUREMENT" ||
            this.$route.matched[i].meta.title === "Reunion en ligne | ICP-E-PROCUREMENT"
          ) {
            return;
          } else {
            breadCrumbs.push({
              href:
                i !== 0 && pathArray[i - (i - lastIndexFound)]
                  ? "/" + pathArray[i - (i - lastIndexFound)] + breadcrumb
                  : breadcrumb,
              disabled: i + 1 === pathArray.length,
              text: this.$route.matched[i].meta.breadcrumb || pathArray[i]
            });
          }
          lastIndexFound = i;
          breadcrumb = "";
        }
      }
      return breadCrumbs;
    }
  },
  created() {
    if (this.$workbox) {
      this.$workbox.addEventListener("waiting", () => {
        this.showUpgradeUI = true;
      });
    }
    this.$store.dispatch("Auth/getAllConfigHome");
    let Virt = [];
    Virt = JSON.parse(localStorage.getItem("userData"));
    this.profile = Virt.profile.status;
    this.user_data = Virt;
    this.$store.dispatch("Auth/loadAllNotice");
    this.$store.dispatch("Auth/loadAllDoc");
    this.$store.dispatch("Auth/loadAllMeeting");
    icp_request.interceptors.response.use(undefined, function(err) {
      return new Promise(function(resolve, reject) {
        if (
          err.status === 401 &&
          err.config &&
          !err.config.__isRetryRequest &&
          this.$store.getters["Auth/isActive"]
        ) {
          this.$store.dispatch("Auth/logOut");
        }
        throw err;
      });
    });
  },
  data() {
    return {
      showUpgradeUI: false,
      navbarOptions: {
        elementId: "main-navbar",
        isUsingVueRouter: true,
        mobileBreakpoint: this.$vuetify.breakpoint.mobileBreakpoint,
        brandImagePath: { name: "Accueil" },
        brandImage: require("@/assets/logo.png"),
        brandImageAltText: "ICP-E-PROCUREMENT__logo",
        collapseButtonOpenColor: "black",
        collapseButtonCloseColor: "black",
        showBrandImageInMobilePopup: true,
        ariaLabelMainNav: "Main Navigation",
        tooltipAnimationType: "shift-away",
        menuOptionsLeft: [
          {
            type: "link",
            text: "Accueil",
            path: { name: "Accueil" }
          },
          {
            type: "link",
            text: "Plans de Passation des Marchés (PPM)",
            path: { name: "public_ppm" }
          },
          {
            type: "link",
            text: "Avis d’Appel à la Concurrence",
            subMenuOptions: [
              {
                type: "link",
                text: "Voir tous les avis",
                path: { name: "welcome_p_notice" }
              },
              {
                type: "link",
                text: "Avis Général de Passation des Marchés/Avis de Pré-information ",
                path: { name: "show_p_notice_by_type1" }
              },
              {
                type: "link",
                text: "Avis de Pré-qualification",
                subText: "Fournitures, Travaux",
                path: { name: "show_p_notice_by_type3" }
              },
              {
                type: "link",
                text: "Avis d’Appel d’Offres",
                subText: "Fournitures, Travaux",
                path: { name: "show_p_notice_by_type4" }
              },
              {
                type: "link",
                text: "Avis de Sollicitation de Prix",
                subText: "Fournitures, Travaux, Prestations Intellectuelles",
                path: { name: "show_p_notice_by_type5" }
              },
              {
                type: "link",
                text: "Avis à Manifestation d’Intérêts",
                subText: "Prestations Intellectuelles, Constitution de la base de données (Entrepreneurs, Fournisseurs, Prestataires)",
                path: { name: "show_p_notice_by_type2" }
              },
              {
                type: "link",
                text: "Avis d’attribution",
                subText: "Provisoire, Définitive",
                path: { name: "show_p_notice_by_type6" }
              }
            ]
          },
          {
            type: "link",
            text: "Contact",
            path: { name: "Contact" },
            iconRight: '<i class="fa fa-long-arrow-right fa-fw"></i>'
          }
        ],
        menuOptionsRight: [
          {
            type: "link",
            text: "Accès Représentant des Soumissionnaires",
            path: { name: "representator" }
          },
          {
            type: "button",
            text: "S'inscrire",
            path: { name: "Registration" },
            class: "button-out lowerCase"
          },
          {
            type: "button",
            class: "login_btn lowerCase",
            text: "Se connecter",
            path: { name: "Connexion" }
          }
        ]
      },
      navbarOptions2: {
        elementId: "main-navbar1",
        isUsingVueRouter: true,
        mobileBreakpoint: this.$vuetify.breakpoint.mobileBreakpoint,
        brandImagePath: { name: "Accueil" },
        brandImage: require("@/assets/logo.png"),
        brandImageAltText: "ICP-E-PROCUREMENT__logo",
        collapseButtonOpenColor: "black",
        collapseButtonCloseColor: "black",
        showBrandImageInMobilePopup: true,
        ariaLabelMainNav: "Main Navigation",
        tooltipAnimationType: "shift-away",
        menuOptionsLeft: [
          {
            type: "link",
            text: "Accueil",
            path: { name: "Accueil" }
          },
          {
            type: "link",
            text: "Plans de Passation des Marchés (PPM)",
            path: { name: "public_ppm" }
          },
          {
            type: "link",
            text: "Avis d’Appel à la Concurrence",
            subMenuOptions: [
              {
                type: "link",
                text: "Voir tous les avis",
                path: { name: "welcome_p_notice" }
              },
              {
                type: "link",
                text: "Avis Général de Passation des Marchés  / Avis de Pré-information ",
                path: { name: "show_p_notice_by_type1" }
              },
              {
                type: "link",
                text: "Avis de Pré-qualification",
                subText: "Fournitures, Travaux",
                path: { name: "show_p_notice_by_type3" }
              },
              {
                type: "link",
                text: "Avis d’Appel d’Offre",
                subText: "Fournitures, Travaux",
                path: { name: "show_p_notice_by_type4" }
              },
              {
                type: "link",
                text: "Avis de Sollicitation de Prix", //"Avis de Demande de Renseignement de Prix à Compétition Ouverte",
                subText: "Fournitures, Travaux, Prestations Intellectuelles",
                path: { name: "show_p_notice_by_type5" }
              },
              {
                type: "link",
                text: "Avis à Manifestation d’Intérêts",
                subText: "Prestations Intellectuelles, Base de Donnees",
                path: { name: "show_p_notice_by_type2" }
              },
              {
                type: "link",
                text: "Avis d’attribution", //"Procès-verbal d’Attribution",
                subText: "Provisoire, Définitive",
                path: { name: "show_p_notice_by_type6" }
              }
            ]
          },
          {
            type: "link",
            text: "Contact",
            path: { name: "Contact" }
          }
        ],
        menuOptionsRight: [
          {
            type: "link",
            text: "FAIRE DES RECOURS EVENTUELS",
            path: { name: "recourse" }
          },
          {
            type: "button",
            class: "login_btn",
            text: "RETOUR A LA PAGE ACTIVE DE TRAVAIL",
            path: { name: "Dashboard" }
          }
        ]
      }
    };
  },
  watch: {
    $route() {
      window.scrollTo(0, 0);
    }
  },
  methods: {
    async accept() {
      this.showUpgradeUI = false;
      await this.$workbox.messageSW({ type: "SKIP_WAITING" });
    }
  }
};
</script>
<style>
/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #00458b #eae7fd;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: #eae7fd;
}

*::-webkit-scrollbar-thumb {
  background-color: #00458b;
  border-radius: 20px;
  border: 3px solid #eae7fd;
}

.cui-exth .WACRibbonPanel {
  display: none !important;
}
/* .v-application{
  width: 100vw !important;
  overflow-x: none !important;
} */
@media (max-width: 890px) {
  html,
  body,
  .v-application,
  .v-application--wrap {
    min-height: 100em !important;
  }
}

div > .v-application--wrap {
  min-height: 100% !important;
}
body {
  background-color: #eae7fd;
  width: 100vw !important;
  text-rendering: optimizeLegibility !important;
  overflow-y: auto !important;
}
#zmmtg-root {
  display: none;
}
.cui-exth .WACRibbonPanel {
  display: none;
}
.offline {
  background-color: hsl(0, 96%, 52%) 74%;
  background-image: linear-gradient(315deg, #fd7d0d 0%, hsl(0, 96%, 52%) 74%);
}
.online {
  background-color: hsl(102, 100%, 50%) 74%;
  background-image: linear-gradient(315deg, #b4fa44 0%, hsl(102, 100%, 50%) 74%);
}
.container {
  background-color: #eae7fd;
}
.mt-5em {
  margin-top: 5em;
  margin-bottom: 5em;
}
.vnb-image {
  width: 6em !important;
  max-height: 6em !important;
}
.vnb {
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  padding-bottom: 5px !important;
  padding-top: 5px !important;
  padding-right: 13px !important;
}
.navigation {
  width: 100% !important;
}
.vnb-button {
  color: #fff !important;
  background-color: #00458b !important;
}
.mx-input:disabled,
.mx-input.disabled {
  color: #730d0d !important;
}
/* .vnb-button .button-out{
  color: #00458b !important;
  background-color: transparent !important;
  border: 1px solid #00458b !important;
} */
.vnb .button-out {
  color: #00458b !important;
  background-color: transparent !important;
  border: 1px solid #00458b !important;
}
.vnb .lowerCase {
  text-transform: capitalize !important;
}

.vnb .button-out:hover {
  color: white !important;
  background-color: #00458b !important;
}
header .v-toolbar__content,
.v-toolbar__extension {
  padding: 0 !important;
}

</style>
